import React from 'react';

import Video from '../Video/Video';

interface VideoBlockProps {
  reverse?: boolean;
  children: JSX.Element;
  videoSrc: string;
}

const VideoCard = ({
  reverse,
  children,
  videoSrc,
}: VideoBlockProps): JSX.Element => (
  <div
    className={`py-9 lg:px-4 flex justify-between ${
      reverse && 'flex-row-reverse'
    } max-lg:flex-col max-lg:gap-8`}
  >
    <div
      className={`w-6/12 max-lg:w-full lg:px-4 self-center ${
        !reverse ? 'lg:mr-14' : 'mr-0'
      }`}
    >
      {children}
    </div>
    <div
      className={`w-6/12 desktop:h-[285px] max-md:h-[160px] md:h-[350px] lg:h-[238px] w-[580px] overflow-hidden max-lg:w-full ${
        reverse ? 'lg:mr-14' : 'mr-0'
      }`}
    >
      <Video src={videoSrc} />
    </div>
  </div>
);

export default VideoCard;
