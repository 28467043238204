import React from 'react';
import { useNavigate } from 'react-router-dom';

import heroImage from '../../../assets/images/hero.png';
import Button from '../../common/Button/Button';
import { ButtonTypes } from '../../common/Button/Button.types';

const Hero = (): JSX.Element => {
  const navigate = useNavigate();
  return (
    <>
      <div className="container max-w-[1400px] flex max-md:flex-col max-md:gap-8 max-md:pt-14 py-6">
        <div className="w-6/12 mr-8 self-center max-md:w-full mx-auto">
          <h1 className="p-0 mb-5 text-4.5xl">
            Fule, taking you to the{' '}
            <span className="font-bold">next level</span>
          </h1>
          <p className="p-0 mb-4">
            Wherever you are in your broking career, feel more confident, work
            smarter not harder and make your business more profitable.
          </p>
          <div className="flex gap-2 flex-wrap ">
            <Button
              text="Free trial"
              onClick={() => navigate('/payment')}
              styles="max-md:w-full"
            />
            <Button
              buttonType={ButtonTypes.SECONDARY}
              text="Learn More"
              styles="max-md:w-full"
              href="#stages"
            />
          </div>
        </div>
        <div className="w-6/12 max-md:w-full">
          <img
            src={heroImage}
            alt="Welcome Image"
            className="mx-auto max-md:max-w-[220px]"
          />
        </div>
      </div>
      <div className="bg-secondary-base">
        <div className="bg-whiteFlow bg-cover bg-no-repeat bg-scroll bg-right-bottom relative top-negative">
          <div className="m-auto max-w-[700px] text-center md:pt-24 pb-36 max-md:px-4">
            <h2 className="p-0 mb-4 text-3xl font-bold">
              Grow. Connect. Learn
            </h2>
            <p className="p-0 mb-4 text-primaryText-2">
              A new tech platform driven by a virtual coach, helping brokers
              with local area marketing, understanding local real estate
              markets, business development, referral network building and
              learning all <span className="font-bold">{"'fuled'"}</span> by
              data.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};
export default Hero;
