/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { CustomArrowProps } from 'react-slick';

const SlickButton = ({
  currentSlide,
  slideCount,
  children,
  ...props
}: CustomArrowProps & { children: JSX.Element }): JSX.Element => (
  <span {...props}>{children}</span>
);

export default SlickButton;
