import React from 'react';

interface VideoProps {
  width?: string;
  height?: string;
  src: string;
  styles?: string;
}
const Video = ({
  width = '1061',
  height = '550',
  src,
  styles = 'max-h-[440px] max-md:h-[200px] max-w-full desktop:mt-[-80px] max-md:mt-[-23px] md:mt-[-50px] lg:mt-[-102px]',
}: VideoProps): JSX.Element => (
  <iframe
    width={width}
    height={height}
    src={src}
    allow="autoplay; fullscreen; picture-in-picture"
    className={styles}
  />
);
export default Video;
