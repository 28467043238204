import React from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

import { AppContextProvider } from './common/context/AppContext';
import Layout from './components/Layout/Layout';
import Home from './pages/Home';
import Payment from './pages/Payment';
import Pricing from './pages/Pricing';
import Privacy from './pages/Privacy';
import Team from './pages/Team';
import TermsAndConditions from './pages/TermsAndConditions';
import ScrollToTop from './utils/hooks/scrollToTop';

const App = (): JSX.Element => (
  <AppContextProvider>
    <BrowserRouter>
      <ScrollToTop>
        <Layout>
          <Routes>
            <Route path="/home" element={<Home />} />
            <Route path="/privacy-policy" element={<Privacy />} />
            <Route
              path="/terms-and-conditions"
              element={<TermsAndConditions />}
            />
            <Route path="/team" element={<Team />} />
            <Route path="/pricing" element={<Pricing />} />
            <Route path="/payment" element={<Payment />} />
            <Route path="*" element={<Navigate to="/home" />} />
          </Routes>
        </Layout>
      </ScrollToTop>
    </BrowserRouter>
  </AppContextProvider>
);

export default App;
