import React, { useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import useSticky from '../../utils/hooks/useSticky';
import Button from '../common/Button/Button';
import { ButtonTypes } from '../common/Button/Button.types';
import { MainLogo } from '../icons/MainLogo';

const Header = (): JSX.Element => {
  const headerRef = useRef<HTMLDivElement | null>(null);
  useSticky(headerRef, 'shadow-lg');

  const navigate = useNavigate();
  const headerBtnStyles =
    'py-3 px-9 max-lg:py-2 max-lg:px-5 max-lg:text-sm max-md:w-full';

  return (
    <>
      <header
        ref={headerRef}
        className="border-t-[12px] border-secondary-base sticky top-negative bg-white z-[500]"
      >
        <div className="flex flex-wrap items-center justify-between container max-w-site px-4 py-5 max-lg:min-h-[80px] gap-5 max-md:gap-2">
          <a href="/" className="flex items-center">
            <MainLogo styles="h-8 mr-3 h-full max-w-[142px] max-lg:max-w-[100px] max-md:max-w-[80px]" />
          </a>
          <div className="flex gap-4 max-md:gap-2 max-md:w-full max-md:flex-col">
            <Button
              buttonType={ButtonTypes.ACTION}
              text="Pricing"
              styles={headerBtnStyles}
              href="/pricing"
            />
            <Button
              text="Free trial"
              styles={headerBtnStyles}
              onClick={() => navigate('/payment')}
            />
            <Button
              buttonType={ButtonTypes.SECONDARY}
              text="Log In"
              styles={headerBtnStyles}
              href="https://app.fule.com.au/"
            />
          </div>
        </div>
      </header>
    </>
  );
};
export default Header;
