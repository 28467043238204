import React from 'react';

import { useAppContext } from '../../common/context/AppContext';
import { Loader } from '../common/Loader/Loader';
import Footer from '../Footer/Footer';
import Header from '../Header/Header';
import WaitlistModal from '../modals/WaitlistModal/WaitlistModal';

const Layout = ({ children }: { children: JSX.Element }): JSX.Element => {
  const { isModalOpen, setIsModalOpen } = useAppContext();
  const { isLoading } = useAppContext();

  return (
    <>
      <div className="flex flex-col justify-between min-h-screen">
        <Header />
        <main>{children}</main>
        <Footer />
      </div>
      {isModalOpen && <WaitlistModal onClose={() => setIsModalOpen(false)} />}
      {isLoading && <Loader />}
    </>
  );
};

export default Layout;
