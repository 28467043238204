import React from 'react';

import TermsAndConditions from '../../../pages/TermsAndConditions';
import Button from '../../common/Button/Button';
import { ButtonTypes } from '../../common/Button/Button.types';
import Modal from '../../common/Modal/Modal';

export const TermsAndConditionsModal: React.FC<{ onClose: () => void }> = ({
  onClose,
}) => (
  <Modal
    onClose={onClose}
    leftGradient
    buttons={
      <Button buttonType={ButtonTypes.ACTION} text="Close" onClick={onClose} />
    }
  >
    <div className="flex">
      <TermsAndConditions isPage={false} />
    </div>
  </Modal>
);
