const env = process.env.REACT_APP_ENV;

let environment: any;
switch (env) {
  case 'local':
  case 'dev':
    environment = {
      stripe_pk:
        'pk_test_51Low93AUgrmy5vR3yL228Rqmlh5Iohp4K0fygbvDGRTwE0kycsTNCpf2cZIHMUrGV1LSyYBWALIl2jiJ3UdfXqnV00W414cwx6',
      stripe_url:
        'https://hpgcnc48p7.execute-api.ap-southeast-2.amazonaws.com/dev/stripe',
      gtmId: 'GTM-5F44ZGF',
    };
    break;
  case 'prod':
    environment = {
      stripe_pk:
        'pk_live_51Low93AUgrmy5vR3g20vEqo4vFqRHYcrKjm2nAxtjaanzaPJlw2nETCn5CN1vMdxv1mgLZRNHYbCrCwOxiXIbQWW00d8AKxyCK',
      stripe_url:
        'https://ipavklvv8a.execute-api.ap-southeast-2.amazonaws.com/prod/stripe',
      gtmId: 'GTM-5F44ZGF',
    };
    break;
}

export { environment };
