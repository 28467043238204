export const aggregators = [
  'AFG',
  'Outsource Financial',
  'Mortgage Advice Bureau',
  'Redrock Group',
  'Other',
  'YBR',
  'VOW',
  'Sure Harvest',
  'SFG',
  'Smartline',
  'Sample & Partners',
  'realestate.com.au',
  'Purple Circle',
  'Our Broker',
  'NewCo Financial Services',
  'NMB',
  'My Local Broker',
  'My Local Aggregation',
  'Mortgage Point',
  'Mortgage Loans Australia',
  'Mortgage House',
  'Mortgage Choice',
  'MoneyQuest',
  'Loans Today',
  'Loankit',
  'Liberty Financial',
  'Loan Market Group',
  'L J Hooker Home Loans',
  'First Chartered Capital Operations',
  'Finsure',
  'Finconnect',
  'Finstreet',
  'Choice',
  'AMAG',
  'Astute',
  'Auspak Financial Services',
  'Aussie',
  'Balmain NB Commercial Mortgages',
  'Bernie Lewis Home Loans',
  'Buyers Choice',
  'Centrepoint Alliance Lending',
  'Connective',
  'eCHoice Home Loans',
  'eChoice.com.au',
  'Elders Home Loans',
  'Fasttrack Finance Group',
  'Finance Systems Technology',
  'Finance King',
];
