import React, { FC } from 'react';

import { IconProps } from '../Icon.types';

export const ArrowRightIcon: FC<IconProps> = ({ fill = '#000000', styles }) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
    className={styles}
  >
    <path
      fill={fill}
      d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
    />
  </svg>
);
