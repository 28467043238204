import React from 'react';

import heroImage from '../assets/images/hero.png';
import { useAppContext } from '../common/context/AppContext';
import PaymentPlanCard from '../components/Payment/PaymentPlanCard/PaymentPlanCard';
import PersonalInfoForm from '../components/Payment/PersonalInfoForm/PersonalInfoForm';

export enum PaymentPlan {
  BASE_PLAN = 'BASE_PLAN',
  STANDARD_PLAN = 'STANDARD_PLAN',
  YEAR_PLAN = 'YEAR_PLAN',
}
const Payment = (): JSX.Element => {
  const { selectedPayment, setSelectedPayment } = useAppContext();

  return (
    <div className="container max-w-[1320px] mt-10 mb-16">
      <div className="flex items-end mb-5">
        <img src={heroImage} alt="Fule Image" className="h-[105px] mr-5" />
        <h1 className="font-bold text-primaryText-2 text-4.5xl max-md:text-3xl max-md:leading-[54px]">
          Payment
        </h1>
      </div>
      <div className="flex gap-5 max-md:flex-col">
        <div className="flex flex-col gap-4">
          <PaymentPlanCard
            type={PaymentPlan.BASE_PLAN}
            isSelected={selectedPayment === PaymentPlan.BASE_PLAN}
            plan="Free trial"
            pricingInfo=""
            onClick={() => setSelectedPayment(PaymentPlan.BASE_PLAN)}
          />
          <PaymentPlanCard
            type={PaymentPlan.STANDARD_PLAN}
            isSelected={selectedPayment === PaymentPlan.STANDARD_PLAN}
            plan="Monthly subscription"
            pricingInfo="per month plus GST"
            price="$75"
            onClick={() => setSelectedPayment(PaymentPlan.STANDARD_PLAN)}
          />
          <PaymentPlanCard
            type={PaymentPlan.YEAR_PLAN}
            isSelected={selectedPayment === PaymentPlan.YEAR_PLAN}
            plan="Annual subscription"
            pricingInfo="per year plus GST (save 10%)"
            price="$810"
            onClick={() => setSelectedPayment(PaymentPlan.YEAR_PLAN)}
          />
        </div>
        <PersonalInfoForm />
      </div>
    </div>
  );
};

export default Payment;
