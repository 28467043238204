import { FormikErrors } from 'formik';

import { PersonalInfo } from '../../common/models/PersonalInfo';
import { FormFields } from '../../components/modals/WaitlistModal/WaitlistModal.types';

const requiredMessage = 'The field is required.';
const emailMessage = 'Invalid email address.';
const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

export const getVimeoUrlVideo = (source: string): string =>
  `https://player.vimeo.com/video/${source}&title=0&muted=1&autoplay=1&autopause=0&controls=0&loop=1&background=1&app_id=122963"`;

export const validateWaitlistFields = (
  values: FormFields,
):
  | void
  | object
  | Promise<FormikErrors<FormFields>>
  | FormikErrors<FormFields> => {
  const errors: FormikErrors<FormFields> = {};
  if (!values.email) {
    errors.email = requiredMessage;
  } else if (!emailRegex.test(values.email)) {
    errors.email = emailMessage;
  }
  if (!values.firstName) {
    errors.firstName = requiredMessage;
  }
  if (!values.lastName) {
    errors.lastName = requiredMessage;
  }
  if (!values.phone) {
    errors.phone = requiredMessage;
  }
  if (!values.policy) {
    errors.policy =
      'You must accept the terms and conditions before sending your message.';
  }
  return errors;
};

export const validatePersonalInfoFields = (
  values: PersonalInfo,
  agreementCheck: boolean,
):
  | void
  | object
  | Promise<FormikErrors<PersonalInfo>>
  | FormikErrors<PersonalInfo> => {
  const errors: FormikErrors<PersonalInfo> = {};
  if (!values.email) {
    errors.email = requiredMessage;
  } else if (!emailRegex.test(values.email)) {
    errors.email = emailMessage;
  }
  if (!values.firstName) {
    errors.firstName = requiredMessage;
  }
  if (!values.lastName) {
    errors.lastName = requiredMessage;
  }
  if (!values.confirmEmail) {
    errors.confirmEmail = requiredMessage;
  }
  if (values.confirmEmail !== values.email) {
    errors.confirmEmail = 'Emails do not match';
  }
  if (agreementCheck && !values.agreement) {
    errors.agreement =
      'You must accept Terms and Conditions before accessing Fule.';
  }
  return errors;
};
