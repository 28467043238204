import React from 'react';

interface TermsAndConditionsLinkProps {
  onClick: () => void;
  className?: string;
}
export const TermsAndConditionsLink: React.FC<TermsAndConditionsLinkProps> = ({
  onClick,
  className = 'my-2 pl-3',
}) => (
  <p className={className}>
    By accessing Fule you agree to our{' '}
    <span
      onClick={e => {
        {
          e.preventDefault();
          e.stopPropagation();
          onClick();
        }
      }}
      className="text-secondary-base cursor-pointer"
    >
      Terms and Conditions
    </span>
  </p>
);
