import { ButtonTypes } from './Button.types';

const defaultBtnStyles =
  'text-lg rounded-md border-2 border-primary-base transition duration-200 ease-in-out text-center';

const disabledPrimaryBtnStyles = 'bg-primary-base text-white';
const disabledSecondaryBtnStyles = 'bg-white text-primary-base';
const disabledActionBtnStyles =
  'bg-secondary-base text-white border-secondary-base';

const primaryBtnStyles = `${disabledPrimaryBtnStyles} hover:shadow-lg hover:border-primary-hover hover:bg-primary-hover`;
const secondaryBtnStyles = `${disabledSecondaryBtnStyles} hover:border-primary-hover hover:text-primary-hover`;
const actionBtnStyles = `${disabledActionBtnStyles} hover:bg-secondary-hover hover:border-secondary-hover hover:shadow-lg`;

const mdBtnStyles = 'py-3 px-9';
const smBtnStyles = 'py-1 px-2';

interface getBtnStylesProps {
  buttonType: ButtonTypes;
  size?: ButtonSize;
  styles?: string;
  disabled?: boolean;
}

export type ButtonSize = 'md' | 'sm';

export const getBtnStyles = ({
  buttonType,
  size = 'md',
  styles = '',
  disabled,
}: getBtnStylesProps): string => {
  const getAllStyles = (
    typeStyles: string,
    disabledTypeStyles: string,
  ): string =>
    `${defaultBtnStyles} ${size === 'md' ? mdBtnStyles : smBtnStyles} ${
      disabled ? disabledTypeStyles : typeStyles
    } ${styles}`;
  switch (buttonType) {
    case ButtonTypes.PRIMARY:
      return getAllStyles(primaryBtnStyles, disabledPrimaryBtnStyles);
    case ButtonTypes.SECONDARY:
      return getAllStyles(secondaryBtnStyles, disabledSecondaryBtnStyles);
    case ButtonTypes.ACTION:
      return getAllStyles(actionBtnStyles, disabledActionBtnStyles);
  }

  buttonType === ButtonTypes.PRIMARY
    ? `${disabled ? disabledPrimaryBtnStyles : primaryBtnStyles} ${styles}`
    : `${disabled ? disabledSecondaryBtnStyles : secondaryBtnStyles} ${styles}`;
};
