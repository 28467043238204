import React from 'react';

import {
  firstHeadingStyles,
  headingStyles,
  orderedListStyles,
  paragraphStyles,
} from './Privacy';

const unorderedListStyles = 'pl-8 mb-8 space-y-1 list-disc';
const romanListStyles = `${orderedListStyles} list-roman`;

const TermsAndConditions = ({ isPage = true }): JSX.Element => (
  <>
    {isPage && (
      <div className="bg-secondary-base text-secondaryText-1 text-[42px] font-semibold text-center min-h-[160px] flex items-center justify-center mt-4">
        <h1>Terms and Conditions</h1>
      </div>
    )}

    <div
      className={`container max-w-site px-6 ${isPage ? 'my-20' : 'mt-5 mb-20'}`}
    >
      <p className="text-end">16 May 2023</p>
      <h3 className={`text-center ${firstHeadingStyles}`}>
        FULE PTY LTD - Terms of Use
      </h3>
      <p className={`${paragraphStyles} italic`}>Effective: 16 May 2023</p>
      <h3 className={firstHeadingStyles}>1. Fule Services</h3>

      <p className={paragraphStyles}>
        Fule products and services, and this website, are provided by Fule Pty
        Ltd ACN 639 503 781. These terms and conditions (the{' '}
        <span className="font-bold">Terms</span>) govern:
      </p>
      <ul className="pl-8 mb-2 space-y-1 list-disc">
        <li>your use of our website at www.fule.com.au (the website);</li>
        <li>business name;</li>
        <li>the apps; and</li>
        <li>products and services offered by Fule,</li>
      </ul>
      <p className={paragraphStyles}>
        (together, the <span className="font-bold">Services</span>).
      </p>
      <p className={paragraphStyles}>
        In using any of the Services, you agree to be bound by these Terms. If
        you do not accept these Terms, you must refrain from using any of the
        Services. These Terms must be read in conjunction with any other
        applicable terms and conditions governing the use of the Services
        including any policies that we may from time to time publish on the
        website and any applicable third party terms and conditions that are
        disclosed to you from time to time.
      </p>
      <h3 className={headingStyles}>2. Terminology</h3>
      <p className={paragraphStyles}>
        In these Terms, the expressions “we”, “us” and “our” refer to Fule Pty
        Ltd ACN 639 503 781.
      </p>
      <h3 className={headingStyles}>3. Amendments to Terms</h3>
      <p className={paragraphStyles}>
        We reserve the right to amend these Terms from time to time. Amendments
        will be effective immediately upon notification on this website. Your
        continued use of the website following such notification will represent
        an agreement by you to be bound by the Terms as amended.
      </p>
      <h3 className={headingStyles}>4. Membership and account</h3>
      <ol className={orderedListStyles}>
        <li>
          <span className="font-bold">Membership</span>. In order to access our
          Services, you must become a member and have an account. To become a
          member, you must complete your registration details in the manner
          described on the website, confirm via the relevant prompts that you
          agree to these Terms, and pay the fees applicable to any Services to
          which you subscribe.
        </li>
        <li>
          <span className="font-bold">Fees</span>. All fees are in Australian
          dollars and are exclusive of GST unless otherwise indicated. All fees
          must be paid in full before you can access the Services. Fees are not
          refundable for change of mind. Payment may be made by way of any of
          the methods listed on our website. We reserve the right to increase
          the fees at yearly intervals on each anniversary of your subscription
          to our Services. We will provide you with sixty (60) days written
          notice prior to any increase, and you may choose not renew your
          subscription to the Services by providing thirty (30) days written
          notice to us in accordance with clause 13.
        </li>
        <li>
          <span className="font-bold">Password and account</span>. Upon
          registration, you will be provided with a password and account
          designation. You must not disclose your password or transfer your
          account to someone else or use someone else’s account. You agree to
          ensure that your registration details are true and accurate at all
          times and you must notify us of any change to the registration details
          as originally supplied. You are responsible for your account and
          everything that happens on that account. This includes that you are
          responsible for any harm or damage to anybody, including us, caused by
          you or someone using your account whether with or without your
          permission.
        </li>
        <li>
          <span className="font-bold">Closing account</span>. You can close your
          account at any time by emailing{' '}
          <a href="mailto:hello@fule.com.au">hello@fule.com.au</a> or via the
          account settings within the fule app (aap.fule.com.au).
        </li>
      </ol>
      <h3 className={headingStyles}>5. Licence</h3>
      <ol className={orderedListStyles}>
        <li>
          <span className="font-bold">Grant of licence</span>. Subject to your
          compliance with these Terms and your payment of all applicable fees
          and compliance with these Terms, we grant you a limited, personal,
          non-exclusive, non- transferable, non-sublicensable and revocable
          licence to use the Services solely to support your finance broking
          business for the term to which you subscribed (Subscription Term). You
          must obtain our written permission to otherwise use the Services for
          any other purpose.
        </li>
        <li>
          <span className="font-bold">Change of Services</span>. Subject to
          these Terms and applicable law, we reserve the right to discontinue,
          withdraw, cancel, interrupt, reschedule or modify the Services for any
          reason and in our complete discretion. We may also provide the
          Services to you via an alternative product or deliver any data through
          an alternative medium, provided that the alternative product or medium
          deliver the same capability and/or data to the Customer. To the
          maximum extent possible, we will provide you with sixty (60) days
          written notice, and a pro-rata refund, discount or other consideration
          to you as reasonably determined by us in our sole discretion.
        </li>
        <li>
          <span className="font-bold">Use of Services</span>. Using the Services
          does not give you ownership of any intellectual property rights in the
          Services you access. Except for the limited licence rights expressly
          granted in these Terms, we reserve all rights in and to the Services
          and any modifications to the Services, including title, ownership,
          intellectual property rights, and any other rights and interests in
          connection with the Services.
        </li>
      </ol>
      <h3 className={headingStyles}>6. Your Use of Services</h3>
      <ol className={orderedListStyles}>
        <li>
          You agree to use the Services for lawful purposes only, and strictly
          in accordance with applicable law, these Terms, and any third-party
          terms and conditions that may be applicable from time to time. You
          must also ensure your access to and use of any of the Services is not
          illegal or prohibited by laws which apply to you.
        </li>
        <li>You agree not to use the Services directly or indirectly to:</li>
        <ol className={romanListStyles}>
          <li>email or otherwise upload any content that:</li>
          <ol className={orderedListStyles}>
            <li>
              infringes any intellectual property or any other rights of any
              person;
            </li>
            <li>
              contains software viruses or any other computer code, files or
              programs designed to interrupt, destroy or limit the functionality
              of any computer software or hardware or telecommunications
              equipment;
            </li>
            <li>poses or creates a privacy or security risk to any person;</li>
            <li>
              constitutes unsolicited or unauthorised advertising, promotional
              materials, commercial activities and/or sales, “junk mail”,
              “spam”, “chain letters”, “pyramid schemes”, “contests”,
              “sweepstakes” or any other form of solicitation;
            </li>
            <li>
              is unlawful, harmful, threatening, abusive, harassing, tortious,
              defamatory, excessively violent, defamatory, vulgar, obscene,
              pornographic, invasive of another’s privacy, or otherwise
              objectionable; or
            </li>
            <li>
              in our sole opinion, is objectionable or which might expose us or
              any other users to harm or liability of any type;
            </li>
          </ol>

          <li>
            extract data from the Services using automated systems or software,
            or for any purpose not expressly permitted by us in these Terms;
          </li>
          <li>
            interfere with or disrupt the Services or servers or networks
            connected to the Services, or disobey any requirements, procedures,
            policies or regulations of networks connected to the Services;
          </li>
          <li>
            impersonate any person or entity, or falsely state or otherwise
            misrepresent your affiliation with a person or entity;
          </li>
          <li>
            solicit personal information from anyone under the age of 18; or
          </li>
          <li>
            harvest or collect email addresses or other contact information of
            other users from the Services by electronic or other means for the
            purposes of sending solicited emails or other unsolicited
            communications.
          </li>
        </ol>
        <li>You must not directly or indirectly:</li>
        <ol className={romanListStyles}>
          <li>
            access, tamper with, or use non-public areas of the platform
            (including content storage), our computer systems or the technical
            delivery systems of our service providers;
          </li>
          <li>
            disable, interfere with or try to circumvent any of the features of
            the platforms related to security or probe, scan or test the
            vulnerability of any of our systems;
          </li>
          <li>
            access or search or attempt to access or search our platform by any
            means (automated or otherwise) other than through our currently
            available search functionalities that are provided via our website,
            mobile apps, or API (and only pursuant to those API terms and
            conditions);
          </li>
          <li>
            scrape, spider, use a robot, or use other automated means of any
            kind to access the Services;
          </li>
          <li>
            decompile, disassemble, reverse engineer, reproduce, redistribute,
            transmit, broadcast, share, lend, alter, modify, adapt, edit, merge
            with other software, create derivative or otherwise attempt to
            derive source code or other trade secrets from the Services;
          </li>
          <li>
            distribute, disclose or allow use of the Services in any format,
            through any timesharing service, service bureau, network or by any
            other means not provided for in these Terms;
          </li>
          <li>
            assign, sell, rent, lease, loan, convey, sublicense, create a
            security interest (to which the Personal Property Securities Act
            2009 (Cth) applies) or otherwise transfer or use any of the Services
            except as expressly provided by these Terms; or
          </li>
          <li>
            permit or encourage any third party from doing any of the above.
          </li>
        </ol>
      </ol>
      <h3 className={headingStyles}>7. Information</h3>
      <p className={paragraphStyles}>
        To the maximum extent permitted by law, you accept and agree that any
        information (including any content, links or advertisements on the
        website) you access via the Services or the website is:
      </p>
      <ul className={unorderedListStyles}>
        <li>
          general information only and is not provided to you in the nature of
          financial advice or any other advice;
        </li>
        <li>
          provided by or sourced from third parties and remains the
          responsibility of those third parties;
        </li>
        <li>accessed is solely at your own risk;</li>
        <li>
          provided to you with your full knowledge that we do not endorse nor
          guarantee the accuracy, reliability, completeness or usefulness of the
          information, nor its fitness for any particular purpose; and
        </li>
        <li>
          not be acted or relied upon by you until you independently verify the
          information and seek independent advice from relevant industry
          professionals to evaluate the accuracy, reliability, completeness and
          usefulness of such information.
        </li>
      </ul>
      <h3 className={headingStyles}>8. Intellectual Property</h3>
      <ul className={unorderedListStyles}>
        <li>
          <span className="font-bold">Our Content</span>. Except for User
          Content (defined below), all data, content and information available
          through the Services, including images, logos, trade marks (registered
          and unregistered), icons, designs, templates, text, information,
          graphics, images, video, sound recordings, software, audio and other
          files, and their selection and arrangement, and all software used to
          provide the Services, is our property or licensed by us (together,{' '}
          <span className="font-bold">Our Content</span>). Our Content must not
          be:
        </li>
        <ol className={romanListStyles}>
          <li>
            modified, copied, adapted, stored, distributed, framed, printed,
            reproduced, published, downloaded, scraped, displayed, posted,
            transmitted, sold or exploited for any purpose in any form or by any
            means, in whole or in part, other than as expressly permitted by
            these Terms; or
          </li>
          <li>
            used to commercialise any information or create derivative works,
            without our written permission or, in the case of third-party
            material, from the owner of the intellectual property rights in the
            material.
          </li>
        </ol>
        <li>
          <span className="font-bold">User Content</span>. The Services may
          enable you to enter or upload your data, content and information (User
          Content) to our Services, or share your User Content on the website
          with us, other users and/or third parties. User Content excludes all
          data, content and information we make available to you on or via the
          Services by or on behalf of us, other users or any other party other
          than you. You retain ownership of the intellectual property rights in,
          and are responsible for, your User Content. To the extent that you
          provide User Content to us, you grant us a fully-transferable,
          royalty-free, perpetual, sub-licensable, non- exclusive, worldwide
          license to copy, distribute, modify, create derivative works, publicly
          perform, publicly display and otherwise use your User Content. This
          licence includes granting us the right to authorise third parties to
          use User Content with other users of the Services on other platforms
          (whether website based or otherwise). We are entitled to remove or
          modify User Content for any reason, including User Content we believe
          violates these Terms or any of our policies.
        </li>
      </ul>
      <h3 className={headingStyles}>9. Privacy</h3>
      <p className={paragraphStyles}>
        We use your data and personal information you provide to us in
        accordance with applicable laws and our privacy policy at
        https://fule.com.au/privacy-policy.
      </p>
      <h3 className={headingStyles}>10. Limitation of Liability</h3>
      <p className={paragraphStyles}>
        Nothing in these Terms excludes, restricts or modifies any condition,
        warranty, right or liability implied in these Terms or protected by law
        to the extent that such exclusion, restriction or modification would
        render these Terms or any provision of these Terms void, illegal or
        unenforceable. Subject to that:
      </p>
      <ol className={orderedListStyles}>
        <li>
          we do not accept responsibility for any loss or damage (including
          indirect, special or consequential loss or damage), however caused
          (including through negligence) which you may directly or indirectly
          suffer in connection with your use of the Services or any linked web
          site or any failure or omission on our part to comply with our
          obligations with these Terms, nor do we accept any responsibility for
          any such loss arising out of your use of or reliance on information
          contained on or accessed through the Services, including in relation
          to the accuracy, completeness or currency of information contained in
          the Services;
        </li>
        <li>
          we exclude all representations or warranties that the information
          provided in the Services is suitable for your purposes or that it is
          reliable, error-free, accurate, up to date, current or complete or
          that your access to that information will be uninterrupted, timely or
          secure; and
        </li>
        <li>
          any condition, warranty, right or liability which would otherwise be
          implied in these Terms or protected by law is excluded.
        </li>
      </ol>
      <h3 className={headingStyles}>11. Australian Consumer Law</h3>
      <p className={paragraphStyles}>
        Pursuant to section 64A of the Australian Consumer Law (under Schedule 2
        of the Competition and Consumer Act 2010 (Cth)) (
        <span className="font-bold">ACL</span>):
      </p>
      <ol className={orderedListStyles}>
        <li>
          this sub-clause applies in respect of any of the Services which are
          not of a kind ordinarily acquired for personal, domestic or household
          use or consumption, provided that this sub-clause will not apply if
          you establish that our reliance on it would not be fair and
          reasonable;
        </li>
        <li>
          this sub-clause applies in respect of any of the Services which are
          not of a kind ordinarily acquired for personal, domestic or household
          use or consumption, provided that this sub-clause will not apply if
          you establish that our reliance on it would not be fair and
          reasonable;
        </li>
        <ol className={romanListStyles}>
          <li>
            in the case of goods, to any one of the following as determined by
            us:{' '}
          </li>
          <ol className={orderedListStyles}>
            <li>
              the replacement of the goods or the supply of equivalent goods; or
            </li>
            <li>the repair of the goods; or</li>
            <li>
              the payment of the cost of replacing the goods or of acquiring
              equivalent goods; or
            </li>
            <li>the payment of the cost of having the goods repaired; </li>
          </ol>
          <li>
            in the case of services, to any one of the following as determined
            by us:{' '}
          </li>
          <ol className={orderedListStyles}>
            <li>the supplying of the services again; or </li>
            <li>
              the payment of the cost of having the services supplied again.
            </li>
          </ol>
        </ol>
      </ol>
      <h3 className={headingStyles}>12. Specific warning</h3>
      <p className={paragraphStyles}>
        You must take your own precautions to ensure that the process which you
        employ for accessing the Services does not expose you to the risk of
        viruses, malicious computer code or other forms of interference which
        may damage your own computer system. For the avoidance of doubt, we do
        not accept responsibility for any interference or damage to your own
        computer system which arises in connection with your use of the
        Services.
      </p>
      <h3 className={headingStyles}>13. Subscription Term and Termination</h3>
      <ol className={orderedListStyles}>
        <li>
          <span>Subscription Term</span>. The Subscription Term starts on the
          date you subscribe to our Services continues for the period you select
          in your subscription and covered by the fees you paid.
        </li>
        <li>
          <span>Renewal</span>. Unless you provide thirty (30) days written
          notice prior to the end of the Term, the Term will automatically renew
          for a further period of the same duration as the previous one,
          provided you continue to pay the applicable fees.
        </li>
        <li>
          <span>Suspension and Termination</span>. Without limiting any rights
          available to us, we may, in our sole discretion, suspend or terminate
          your account or your use of the Service for reasons such as non-
          payment of fees, suspected fraudulent, abusive or illegal activity,
          lack of use of the Services, or you breach of any of these Terms which
          is not able to be remedied within 14 days, and/or violate or act
          inconsistently with any other policy that we publish from time to time
          on the website. You acknowledge that if your account is suspended or
          terminated, all information related to that account and files in your
          account may be deleted, or you may be barred from accessing such files
          and information. Subject to any rights you have under any applicable
          laws, we will not be liable for and you release us from all liability
          associated with any actions we may take in relation to the suspension
          or termination of your account or your use of the Services under these
          Terms.
        </li>
      </ol>
      <h3 className={headingStyles}>14. Interactions between users</h3>
      <p className={paragraphStyles}>
        You are solely responsible for your dealings and interactions with any
        other users or third parties in connection with the Services. We reserve
        the right but have no obligation to become involved in any way with
        disputes between you and any other user of the Services.
      </p>
      <h3 className={headingStyles}>15. Indemnity and Release</h3>
      <p className={paragraphStyles}>
        To the fullest extent permitted by law, you hereby release, indemnify
        and hold us and our related bodies corporate and their officers,
        employees, directors and agents harmless from any and all losses,
        damages, expenses, including legal costs and expenses reasonably
        incurred, rights, claims, actions of any kind and injury (including
        death) arising out of or relating to your use of the Services, any User
        Content, your breach of these Terms or any conduct of yours that is
        fraudulent, illegal or negligent.
      </p>
      <h3 className={headingStyles}>16. General</h3>
      <p className={paragraphStyles}>
        These Terms are governed by the laws of New South Wales, Australia,
        excluding to the greatest extent possible its conflict of law
        provisions. You agree to submit to the exclusive jurisdiction of New
        South Wales, Australia. Our failure to exercise or enforce any right or
        provision of these Terms does not constitute a waiver of such right or
        provision. If any provision of these Terms is found by a court of
        competent jurisdiction to be invalid, the parties agree that the court
        should endeavour to give effect to the parties’ intention and the other
        provisions of these Terms remain in full force and effect. We may assign
        all or any of our rights under these Terms without restriction. We may
        from time to time, update or vary these Terms. Words like “include and
        “including” are not words of limitation.
      </p>
      <h3 className={headingStyles}>17. Our contact information</h3>
      <p className={paragraphStyles}>
        You can reach us at{' '}
        <a href="mailto:hello@fule.com.au">hello@fule.com.au</a> with any
        questions or comments.
      </p>
    </div>
  </>
);

export default TermsAndConditions;
