import { FieldProps } from 'formik';
import React, { FC } from 'react';

interface CheckboxProps {
  children: React.ReactElement;
  id: string;
  isValid?: boolean;
  isChecked?: boolean;
  setIsChecked?: (a: boolean) => void;
  setIsTouched?: (a: boolean) => void;
}

const Checkbox: FC<CheckboxProps & Partial<FieldProps>> = ({
  id,
  children,
  field,
  form,
  isChecked,
  setIsTouched,
  setIsChecked,
  ...props
}): JSX.Element => (
  <div className="flex flex-col">
    <div className="flex items-center relative">
      <label className="flex items-center">
        <input
          id={id}
          type="checkbox"
          checked={field?.checked ?? isChecked}
          onChange={
            field?.onChange ??
            (() => {
              setIsChecked?.(!isChecked);
              setIsTouched?.(true);
            })
          }
          className="w-4 h-4 text-blue-600 bg-gray-100 border border-gray-300 rounded appearance-none checked:bg-gray-light checked:border-none after:content-[''] after:bg-checkbox after:bg-no-repeat after:bg-center after:w-4 after:h-4 after:block"
          {...field}
          {...props}
        />
        {children}
      </label>
    </div>
    {field && form && form.touched[field.name] && form.errors[field.name] && (
      <span className="text-error text-sm mt-1">
        {form.errors[field.name] as string}
      </span>
    )}
  </div>
);

export default Checkbox;
