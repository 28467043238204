import './index.css';

import React from 'react';
import ReactDOM from 'react-dom/client';
import TagManager from 'react-gtm-module';

import App from './App';
import { environment } from './environment';

TagManager.initialize({ gtmId: environment.gtmId });

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);
