import React, { useEffect, useState } from 'react';

const useSticky = (
  ref: React.RefObject<HTMLDivElement>,
  stuckClasses: string,
): void => {
  const [stuck, setStuck] = useState(false);

  useEffect(() => {
    stuck
      ? ref.current?.classList.add(stuckClasses)
      : ref.current?.classList.remove(stuckClasses);
  }, [stuck]);

  useEffect(() => {
    const cachedRef = ref.current as Element;
    const observer = new IntersectionObserver(
      ([e]) => setStuck(e.intersectionRatio < 1),
      { threshold: [1] },
    );
    observer.observe(cachedRef);
    return () => observer.unobserve(cachedRef);
  }, [ref]);
};

export default useSticky;
