import React, { FC } from 'react';

import { IconProps } from './Icon.types';

export const ListIcon: FC<IconProps> = ({ fill = '#ffad00', styles }) => (
  <svg
    viewBox="0 0 512 512"
    height="16"
    width="16"
    xmlns="http://www.w3.org/2000/svg"
    className={styles}
    fill={fill}
    aria-hidden="true"
  >
    <path d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z" />
  </svg>
);
