import React from 'react';
import { Link } from 'react-router-dom';

import { useAppContext } from '../../common/context/AppContext';
import { FacebookIcon } from '../icons/social/FacebookIcon';
import { InstagramIcon } from '../icons/social/InstagramIcon';
import { LinkedinIcon } from '../icons/social/LinkedinIcon';
import { socialIcon } from './Footer.types';

const socialIcons: socialIcon[] = [
  {
    icon: FacebookIcon,
    href: 'https://www.facebook.com/Fule-108252018484353',
  },
  {
    icon: InstagramIcon,
    href: 'https://www.instagram.com/fuleforbrokers/?hl=en',
  },
  {
    icon: LinkedinIcon,
    href: 'https://www.linkedin.com/company/fule-pty-ltd/',
  },
];

const linkStyles =
  'p-2 cursor-pointer text-primaryText-2 hover:text-primaryText-1';

const Footer = (): JSX.Element => {
  const { setIsModalOpen } = useAppContext();
  return (
    <>
      <footer className="bg-secondaryText-2 px-4 pt-28 pb-8 max-sm:pt-24 text-sm relative top-negative">
        <div className="w-full mx-auto py-4 flex justify-center lg:justify-end lg:items-center max-w-[1488px]">
          <ul className="flex flex-wrap items-center mt-3 sm:mt-0 max-sm:flex-col">
            <li className={linkStyles}>
              <Link to="/team">Team</Link>
            </li>
            <li className={linkStyles}>
              <Link to="/privacy-policy">Privacy Policy</Link>
            </li>
            <li className={linkStyles}>
              <Link to="/terms-and-conditions">Terms and Conditions</Link>
            </li>
            <li className={linkStyles}>
              <a
                onClick={e => {
                  e?.stopPropagation();
                  setIsModalOpen(true);
                }}
              >
                Contact us
              </a>
            </li>
          </ul>
        </div>
        <div className="w-full mx-auto min-lg:p-4 flex max-lg:flex-col max-lg:flex-col-reverse items-center lg:justify-between max-w-[1488px]">
          <p>© 2023 Fule.</p>
          <div className="flex gap-2">
            {socialIcons.map(({ href, icon }, index) => (
              <a
                href={href}
                target="_blank"
                rel="noreferrer"
                className="p-3"
                key={`footerIcon-${index}`}
              >
                {icon({ styles: 'w-6 h-6 hover:fill-primaryText-2' })}
              </a>
            ))}
          </div>
        </div>
      </footer>
    </>
  );
};
export default Footer;
