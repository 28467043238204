import React, { useRef } from 'react';

import useOutsideAlerter from '../../../utils/hooks/useOutsideAlerter';
import { CloseIcon } from '../../icons/CloseIcon';
import { ModalProps } from './Modal.types';

const Modal = ({
  children,
  onClose,
  styles,
  buttons,
  leftGradient,
}: ModalProps): JSX.Element => {
  const modalRef = useRef<HTMLDivElement | null>(null);
  useOutsideAlerter(modalRef, () => onClose?.());
  return (
    <div className="fixed top-0 left-0 right-0 bg-modalBg w-screen h-screen flex items-center justify-center z-[10000]">
      <div
        ref={modalRef}
        className={`relative w-full max-w-xl max-h-[600px] bg-white shadow-lg p-4 m-4 ${styles}`}
      >
        {leftGradient && (
          <div className="absolute w-9 h-[95%] bg-gradient-to-b from-secondary-base from-0% to-secondary-hover to-100% rounded-lg" />
        )}{' '}
        <button
          className="p-1.5 mr-0 ml-auto block sticky absolute top-0"
          onClick={onClose}
        >
          <CloseIcon styles="h-3 w-3" />
        </button>
        <div
          className={`max-h-[490px] overflow-y-scroll Modal ${
            leftGradient && 'ml-10'
          }`}
        >
          {children}
        </div>
        <div className="absolute bottom-0 sticky flex justify-end mt-2">
          {buttons}
        </div>
      </div>
    </div>
  );
};

export default Modal;
