import React from 'react';

import { getVimeoUrlVideo } from '../../../utils/helpers/helpers';
import VideoCard from '../../VideoCard/VideoCard';

const titleStyles = 'p-0 mb-4 text-3xl';
const subtitleStyles = 'p-0 text-primaryText-2';

const Videos = (): JSX.Element => (
  <div className="pt-20 px-4 pb-6 container max-w-site">
    <VideoCard videoSrc={getVimeoUrlVideo('840673154?h=329179d99d')}>
      <>
        <h2 className={titleStyles}>
          “I feel
          <span className="font-bold"> isolated and overwhelmed </span>
          and don’t know where to start”
        </h2>
        <p className={subtitleStyles}>
          Customised recommendations on how best to start your day.
        </p>
      </>
    </VideoCard>
    <VideoCard reverse videoSrc={getVimeoUrlVideo('840670924?h=9fd07575ee')}>
      <>
        <h2 className={titleStyles}>
          “I know I should be growing my network but I
          <span className="font-bold"> don’t have the time</span>“
        </h2>
        <p className={subtitleStyles}>
          Access to Australia’s largest and most complete business database with
          talking points, product recommendations and coaching on how to turn a
          contact into a client.
        </p>
      </>
    </VideoCard>
    <VideoCard videoSrc={getVimeoUrlVideo('840670636?h=01e4d2f81c')}>
      <>
        <h2 className={titleStyles}>
          “It’s not good enough to be just a loan writer,
          <span className="font-bold">
            {' '}
            I need to be an expert in all things
          </span>
          “
        </h2>
        <p className={subtitleStyles}>
          All the information you need to understand what’s influencing property
          prices, the thing your customers care about most, with tips on how to
          use it.
        </p>
      </>
    </VideoCard>
    <VideoCard reverse videoSrc={getVimeoUrlVideo('840671161?h=42a7e7b170')}>
      <>
        <h2 className={titleStyles}>
          “<span className="font-bold">I know I should be on socials </span>
          but don’t have the time to create the content and be consistent”
        </h2>
        <p className={subtitleStyles}>
          Regularly updated, ready to send content to entertain, educate &
          engage.
        </p>
      </>
    </VideoCard>
    <VideoCard videoSrc={getVimeoUrlVideo('840671486?h=d938895046')}>
      <>
        <h2 className={titleStyles}>
          “I have to
          <span className="font-bold"> scroll through old emails </span>
          and text messages to find information on current offers”
        </h2>
        <p className={subtitleStyles}>
          All the offers from residential & SME lenders, up to date and in one
          place with intuitive search.
        </p>
      </>
    </VideoCard>
    <VideoCard reverse videoSrc={getVimeoUrlVideo('840675161?h=c15a02443c')}>
      <>
        <h2 className={titleStyles}>
          “I’d like
          <span className="font-bold"> coaching and mentoring </span>
          but its expensive and doesn’t fit in with my schedule”
        </h2>
        <p className={subtitleStyles}>
          Get the most out of the data Fule provides with easy, simple tips and
          recommendations on how to use it. Extensive coaching support,
          templates, processes and more for whatever stage you are at in
          business. Time on Fule also counts towards your CPD!
        </p>
      </>
    </VideoCard>
  </div>
);
export default Videos;
