import React from 'react';

export const paragraphStyles = 'mb-4';
export const firstHeadingStyles = 'text-26 font-bold mb-4';
export const headingStyles = `${firstHeadingStyles} mt-10`;
export const firstSubheadingStyles = `${firstHeadingStyles} text-[22px]`;
export const subheadingStyles = `${firstSubheadingStyles} mt-10`;
export const orderedListStyles = 'pl-8 mb-8 space-y-1 list-alpha list-inside';

const Privacy = (): JSX.Element => (
  <>
    <div className="bg-secondary-base text-secondaryText-1 text-[42px] font-semibold text-center min-h-[160px] flex items-center justify-center mt-4">
      <h1>Privacy Policy</h1>
    </div>
    <div className="container max-w-site px-6 my-20">
      <h3 className={firstHeadingStyles}>Introduction</h3>
      <p className={paragraphStyles}>
        FULE PTY LTD (ACN 639 503 781) (we, us, our) recognises the importance
        of protecting the privacy and the rights of individuals in relation to
        their personal information.
      </p>
      <p className={paragraphStyles}>
        Our first priority is to collect, store and use data both securely and
        ethically. We are dedicated to ensuring we adhere not only to the legal
        standards but to industry best practice or better and will constantly
        look for the latest technology and innovation to keep your data as safe
        as possible.
      </p>
      <p className={paragraphStyles}>
        This privacy policy sets out our commitment to protecting the privacy of
        your personal information and how it is collected whether through our
        website (<span className="font-bold">Site</span>), the Fule platform,
        directly from you or otherwise.
      </p>
      <p className={paragraphStyles}>
        We respect your rights to privacy under the{' '}
        <span className="italic">Privacy Act 1988</span> (Cth) (
        <span className="font-bold">Privacy Act</span>) and the Australian
        Privacy Principles, and we manage the collection and disclosure of your
        personal information in accordance with these requirements.
      </p>
      <p className={paragraphStyles}>
        We also uphold your rights to privacy if you are based in the European
        Union, in accordance with the{' '}
        <span className="italic">General Data Protection Regulation </span>
        (EU) (<span className="font-bold">GDPR</span>). Your rights under the
        GDPR are listed in clause 12.
      </p>
      <p className={paragraphStyles}>
        If you do not wish to provide personal information to us, then you do
        not have to do so. However, this may affect your use of this Site or any
        services offered on it.
      </p>
      <h3 className={headingStyles}>What is your personal information?</h3>
      <p className={paragraphStyles}>
        When used in this privacy policy, the term “personal information” has
        the meaning given to it in the Privacy Act and GDPR.
      </p>
      <p className={paragraphStyles}>
        In general terms, it is any information that can be used to personally
        identify you. This may include your name, address, telephone number,
        email address and profession or occupation.
      </p>
      <p className={paragraphStyles}>
        If the information we collect personally identifies you, or you are
        reasonably identifiable from it, the information will be considered
        personal information.
      </p>
      <p className={paragraphStyles}>
        We may also collect some information that is not personal information
        because it does not identify you or anyone else. For example, we may
        collect anonymous answers to surveys or aggregated information about how
        users use our website.
      </p>
      <p className={paragraphStyles}>
        Your personal information will not be shared, sold, rented or disclosed
        other than as described in this privacy policy.
      </p>
      <h3 className={headingStyles}>
        What information may we collect from you?
      </h3>
      <p className={paragraphStyles}>
        We may collect the following personal information from you:
      </p>
      <ol className={orderedListStyles}>
        <li>name;</li>
        <li>business name;</li>
        <li>billing and business address;</li>
        <li>email address, telephone number and other contact details;</li>
        <li>
          Australian Business Numbers (ABN) and Australian Company Numbers (ACN)
        </li>
        <li>
          Credit or debit card details and other payment services for payments
          and refunds
        </li>
      </ol>
      <p className={paragraphStyles}>
        We collect personal information about you so that we can perform our
        business activities and functions and to provide the best possible
        quality of customer service. We collect, hold, use and disclose your
        personal information for the following purposes:
      </p>
      <ol className={orderedListStyles}>
        <li>to provide our services to you;</li>
        <li>to conduct internal record keeping;</li>
        <li>to monitor website and platform usage</li>
        <li>to identify and understand user needs;</li>
        <li>to send communications requested by you;</li>
        <li>
          to answer enquiries and provide information or advice about existing
          and new services;
        </li>
        <li>
          to send you promotional information about 3<sup>rd</sup> parties that
          we think you may find interesting;
        </li>
        <li>
          to comply with any law, rule, regulation, lawful and binding
          determination, decision or direction of a regulator, or in cooperation
          with any governmental authority
        </li>
      </ol>
      <h3 className={headingStyles}>
        How do we collect your personal <br />
        information?
      </h3>
      <p className={paragraphStyles}>
        We collect your personal information directly from you unless it is
        unreasonable or impracticable to do so. When collecting personal
        information from you, we may collect in ways including:
      </p>
      <ol className={orderedListStyles}>
        <li>when you make an enquiry about our services;</li>
        <li>
          when you fill in any of our online forms, including when downloading
          free publications or making online bookings;
        </li>
        <li>
          through your access and use of our website, including when you
          register as a member;
        </li>
        <li>during conversations between you and our representatives;</li>
        <li>
          when you ask to be placed on one of our subscription/mailing lists;
        </li>
        <li>
          when you become a client or customer of ours or otherwise use our;
        </li>
        <li>
          when you visit any links shared via our online posts, emails or other
          landing pages;
        </li>
        <li>through government databases or credit reporting agencies.</li>
      </ol>
      <h3 className={headingStyles}>
        What happens if we can’t collect your personal information?
      </h3>
      <p className={paragraphStyles}>
        If you do not provide us with the personal information described above,
        some or all of the following may happen:
      </p>
      <ol className={orderedListStyles}>
        <li>
          we may not be able to provide our services to you, either to the same
          standard or at all;
        </li>
        <li>
          we may not be able to provide you with information about services that
          you may want, including information about special promotions.
        </li>
      </ol>
      <h3 className={headingStyles}>
        What information we collect from other sources
      </h3>
      <p className={paragraphStyles}>
        Fule collects data from a variety of government, open source and third
        party data providers. We strive to ensure the data we collect is
        accurate and relevant however as data is from other parties we cannot
        guarantee the accuracy or completeness. The Fule platform enables users
        to provide feedback where data may not be up to date or relevant.
      </p>
      <p className={paragraphStyles}>
        Data is collected from a range of sources including:
      </p>
      <ol className={orderedListStyles}>
        <li>
          Government data portals (data.gov.au and state/territory portals)
        </li>
        <li>Government agencies including ASIC, ATO and APRA</li>
        <li>ABS including census</li>
        <li>Department of education (state and territories)</li>
        <li>Other third party websites</li>
      </ol>
      <h3 className={headingStyles}>Our website</h3>
      <h4 className={firstSubheadingStyles}>Site User Tracking Experience</h4>
      <p className={paragraphStyles}>
        We may use tracking software to review and improve your experience of
        our Site, Surveys and landing pages. In particular, we may use Google
        Analytics Advertising products: Remarketing with Google Analytics and
        Google Analytics Demographics and Interest Reporting.
      </p>
      <p className={paragraphStyles}>
        Google Analytics collects data about our Site traffic via Google
        Advertising cookies and anonymous identifiers.
      </p>
      <p className={paragraphStyles}>
        Data collected via these Google products is not linked with any
        personally identifiable information you submit while on our Site. If you
        wish to opt out of the Google Analytics data collection, you may do so
        on Google’s Site at{' '}
        <a
          className="hover:text-primaryText-2"
          href="https://tools.google.com/dlpage/gaoptout/"
        >
          tools.google.com/dlpage/gaoptout
        </a>
      </p>
      <h4 className={subheadingStyles}>Cookies</h4>
      <p className={paragraphStyles}>
        When you access our Site, we may send a “cookie” (which is a small
        summary file containing a unique ID number) to your computer. This
        enables us to recognise your computer and greet you each time you visit
        our website without bothering you with a request to register. It also
        enables us to keep track of services you view so that, if you consent,
        we can send you news about those services.
      </p>
      <p className={paragraphStyles}>
        We also use cookies to measure traffic patterns, to determine which
        areas of our website have been visited and to measure transaction
        patterns in the aggregate.
      </p>
      <p className={paragraphStyles}>
        We use this to research our users’ habits so that we can improve our
        online services. Our cookies do not collect personal information. If you
        do not wish to receive cookies, you can set your browser so that your
        computer does not accept them. We may log IP addresses (that is, the
        electronic addresses of computers connected to the Internet) to analyse
        trends, administer the website, track users’ movements, and gather broad
        demographic information.
      </p>
      <p className={paragraphStyles}>
        The website may contain content and sharing tools embedded from various
        social networks, such as Facebook. These suppliers may use and place
        cookies on your device. We do not have access to, and cannot control,
        these cookies or the personal data and information that they may
        collect. You therefore need to check the websites of these suppliers to
        get further information on how they manage cookies and what information
        their cookies collect.
      </p>
      <h4 className={subheadingStyles}>Security</h4>
      <p className={paragraphStyles}>
        As our website is linked to the Internet, and the Internet is inherently
        insecure, we cannot provide any assurance regarding the security of
        transmission of information you communicate to us online.
      </p>
      <p className={paragraphStyles}>
        We also cannot guarantee that the information you supply will not be
        intercepted while being transmitted over the Internet. Accordingly, any
        personal information or other information which you transmit to us
        online is transmitted at your own risk.
      </p>
      <h4 className={subheadingStyles}>Links</h4>
      <p className={paragraphStyles}>
        We provide links to websites outside of our website, as well as to 3
        <sup>rd</sup> party websites. These linked sites are not under our
        control, and we cannot accept responsibility for the conduct of any
        companies, businesses, affiliates, advertisers and sponsors, linked to
        our website.
      </p>
      <p className={paragraphStyles}>
        Before disclosing your personal information on any other website or to
        any 3<sup>rd</sup> party, we advise you to examine the terms and
        conditions of using that website and its privacy policy. 3<sup>rd</sup>{' '}
        party websites are responsible for informing you about their own privacy
        practices.
      </p>
      <h4 className={subheadingStyles}>
        Who do we disclose your information to?
      </h4>
      <p className={paragraphStyles}>
        We may disclose your personal information to:
      </p>
      <ol className={orderedListStyles}>
        <li>
          our directors, employees, agents, contractors or service providers for
          the purposes of operating our website or our business, fulfilling
          requests by you, and to otherwise provide services to you including,
          without limitation, web hosting providers, IT systems administrators,
          mailing houses, couriers, payment processors, data entry service
          providers, electronic network administrators, debt collectors, and
          professional advisors such as accountants, solicitors, business
          advisors and consultants;
        </li>
        <li>
          suppliers and other 3<sup>rd</sup> parties with whom we have
          commercial relationships, for business, marketing, and related
          purposes, which may include overseas parties;
        </li>
        <li>
          credit reporting agencies and courts, tribunals, regulatory
          authorities where customers fail to pay for goods or services provided
          by us to them, and other law enforcement officers as required by Law;
        </li>
        <li>
          where we are required to do so by law, such as under the{' '}
          <span className="italic">
            Anti-Money or Laundering and Courter Terrorism Financing Act 2006
          </span>{' '}
          (Cth) or in accordance with a subpoena or summons issued by a court;
        </li>
        <li>associated businesses that may want to market products to you;</li>
        <li>
          companies that provide information and infrastructure systems to us;
        </li>
        <li>anyone, where you have provided us consent;</li>
        <li>
          investors, agents or advisers, or any entity that has an interest in
          our business;
        </li>
        <li>
          any other organisation for any authorised purpose with your express
          consent.
        </li>
      </ol>
      <p className={paragraphStyles}>
        We only disclose this information if the 3<sup>rd</sup> party has agreed
        to comply with the standards in our privacy policy.
      </p>
      <p className={paragraphStyles}>
        If there is any change or potential change to the control of our
        business pursuant to the sale, assignment or transfer of the business,
        or business assets, its assets and/or liabilities, we reserve the right
        to sell, assign and/or transfer our user databases, together with any
        personal information and non-personal information contained in those
        databases to the extent permitted by law. In that event, your personal
        information may be disclosed to a potential purchaser, assignee or
        transferee, however any disclosures will only be made in good faith and
        where confidentiality is maintained.
      </p>
      <h4 className={subheadingStyles}>Direct marketing materials</h4>
      <p className={paragraphStyles}>
        We may send you direct marketing communications and information about
        our services that we consider may be of interest to you. These
        communications may be sent in various forms, including mail, SMS and
        email, in accordance with applicable marketing laws, such as the
        <span className="italic"> Spam Act 2003 </span>
        (Cth).
      </p>
      <p className={paragraphStyles}>
        If you indicate a preference for a method of communication, we will
        endeavour to use that method whenever practical to do so.
      </p>
      <p className={paragraphStyles}>
        In addition, at any time you may opt-out of receiving marketing
        communications from us by contacting us (see the details below) or by
        using opt-out facilities provided in the marketing communications and we
        will then ensure that your name is removed from our subscription/mailing
        list.
      </p>
      <p className={paragraphStyles}>
        We do not provide your personal information to other organisations for
        the purposes of direct marketing.
      </p>
      <h4 className={subheadingStyles}>Security and data quality</h4>
      <p className={paragraphStyles}>
        We take reasonable steps to ensure your personal information is
        protected from misuse and loss and from unauthorised access,
        modification or disclosure.
      </p>
      <p className={paragraphStyles}>
        We strive to ensure the security, integrity and privacy of personal
        information that you submit to us through our website. Unfortunately, no
        data transmission over the Internet can be guaranteed to be totally
        secure.
      </p>
      <p className={paragraphStyles}>
        We endeavour to take all reasonable steps to protect the personal
        information you may transmit to us or from our online services. Once we
        do receive your transmission, we will also make our best efforts to
        ensure its security on our systems.
      </p>
      <p className={paragraphStyles}>
        In addition, our employees and the contractors who provide services
        related to our information systems are obliged to respect the
        confidentiality of any personal information held by us.
      </p>
      <p className={paragraphStyles}>
        We may hold your information in either electronic or hard copy form.
        Personal information is destroyed or de-identified when no longer needed
        or when we are no longer required by Law to retain it (whichever is the
        latter).
      </p>
      <h4 className={subheadingStyles}>
        How long do we retain your personal data?
      </h4>
      <p className={paragraphStyles}>
        We will only keep your personal data for as long as necessary to fulfil
        the purposes we collected it for, including for the purposes of
        satisfying any legal, accounting, or reporting requirements.
      </p>
      <p className={paragraphStyles}>
        To determine the appropriate retention period for personal data, we
        consider the amount, nature, and sensitivity of the personal data, the
        potential risk of harm from unauthorised use or disclosure of your
        personal data, the purposes for which we process your personal data and
        whether we can achieve those purposes through other means, and the
        applicable legal requirements.
      </p>
      <p className={paragraphStyles}>
        By Law we have to keep basic information about our customers (including
        contact, identity, financial and transaction data) for five years for
        Australian tax law purposes.
      </p>
      <p className={paragraphStyles}>
        In some circumstances you can ask us to delete your data; see your legal
        rights below for further information.
      </p>
      <p className={paragraphStyles}>
        In some circumstances we may anonymise your personal data (so that it
        can no longer be associated with you) for research or statistical
        purposes in which case we may use this information indefinitely without
        further notice to you.{' '}
      </p>
      <h4 className={subheadingStyles}>
        If I am based in the EU, what are my legal rights under the GDPR?
      </h4>
      <p className={paragraphStyles}>
        If the General Data Protection Regulation applies to you because you are
        in the European Union, you have rights under data protection laws in
        relation to your personal data:
      </p>
      <ol className={orderedListStyles}>
        <li>
          The right to be informed – that’s an obligation on us to inform you
          how we use your personal data;
        </li>
        <li>
          The right of access – that’s a right to make what’s known as a ‘data
          subject access request’ for copy of the personal data we hold about
          you;
        </li>
        <li>
          The right to rectification – that’s a right to make us correct
          personal data about you that may be incomplete or inaccurate;
        </li>
        <li>
          The right to erasure – that’s also known as the ‘right to be
          forgotten’ where in certain circumstances you can ask us to delete the
          personal data we have about you (unless there’s an overriding legal
          reason we need to keep it);
        </li>
        <li>
          The right to restrict processing – that’s a right for you in certain
          circumstances to ask us to suspend processing personal data;
        </li>
        <li>
          The right to data portability – that’s a right for you to ask us for a
          copy of your personal data in a common format (for example, a .csv
          file);
        </li>
        <li>
          The right to object – that’s a right for you to object to us
          processing your personal data (for example, if you object to us
          processing your data for direct marketing); and
        </li>
        <li>
          Rights in relation to automated decision making and profiling – that’s
          a right you have for us to be transparent about any profiling we do,
          or any automated decision making.
        </li>
      </ol>
      <p className={paragraphStyles}>
        These rights are subject to certain rules around when you can exercise
        them.
      </p>
      <p className={paragraphStyles}>
        If you wish to exercise any of the rights set out above, please contact
        us at <a href="mailto:robyn@fule.com.au">robyn@fule.com.au.</a>
      </p>
      <h4 className={subheadingStyles}>
        How can you access and correct your personal information?
      </h4>
      <p className={paragraphStyles}>
        You may request access to any personal information we hold about you at
        any time by contacting us (see the details below).
      </p>
      <p className={paragraphStyles}>
        Where we hold information that you are entitled to access, we will try
        to provide you with suitable means of accessing it (for example, by
        mailing or emailing it to you).{' '}
      </p>
      <p className={paragraphStyles}>
        An explanation may be provided to you if we deny you access to the
        personal information we hold about you.
      </p>
      <p className={paragraphStyles}>
        We may charge you a reasonable fee to cover our administrative and other
        reasonable costs in providing the information to you.
      </p>
      <p className={paragraphStyles}>
        We will not charge for simply making the request and will not charge for
        making any corrections to your personal information.
      </p>
      <p className={paragraphStyles}>
        There may be instances where we cannot grant you access to the personal
        information we hold. For example, we may need to refuse access if
        granting access would interfere with the privacy of others or if it
        would result in a breach of confidentiality. If that happens, we will
        give you written reasons for any refusal.
      </p>
      <p className={paragraphStyles}>
        If you believe that personal information we hold about you is incorrect,
        incomplete or inaccurate, then you may request us to amend it. We will
        consider if the information requires correction. If we do not agree that
        there are grounds for correction, then we will add a note to the
        personal information stating that you disagree with it.
      </p>
      <h4 className={subheadingStyles}>
        How can you withdraw your consent to this privacy policy?
      </h4>
      <p className={paragraphStyles}>
        You may withdraw your consent to this privacy policy at any point. If
        you wish to withdraw your consent to our collection and retention of
        your data, please contact our Data Protection Officer at{' '}
        <a href="mailto:robyn@fule.com.au">robyn@fule.com.au.</a> and we can
        arrange for your data to be deleted. However, this may affect your use
        of this Site or any services offered on it.
      </p>
      <p className={paragraphStyles}>
        You may choose to restrict the collection or use of your personal
        information. If you have previously agreed to us using your personal
        information for direct marketing purposes, you may change your mind at
        any time by contacting us at the email address listed in this privacy
        policy.
      </p>
      <p className={paragraphStyles}>
        To unsubscribe from our email database, or opt out of any
        communications, please contact us at the email address listed at the
        start of the privacy policy, with “Unsubscribe” in the subject line of
        the e-mail.
      </p>
      <h3 className={headingStyles}>
        What is the process for complaining about a breach of privacy?
      </h3>
      <p className={paragraphStyles}>
        If you believe that your privacy has been breached, please contact us
        using the contact information below and provide details of the incident
        so that we can investigate it.
      </p>
      <p className={paragraphStyles}>
        We will treat your complaint confidentially, investigate your complaint
        and aim to ensure that we contact you and your complaint is resolved
        within a reasonable time (and in any event within the time required by
        the Privacy Act and/or the GDPR, if applicable).
      </p>
      <h3 className={headingStyles}>Contacting us</h3>
      <p className={paragraphStyles}>
        If you have any questions about this privacy policy, any concerns or a
        complaint regarding the treatment of your privacy or a possible breach
        of your privacy, please contact us at{' '}
        <a href="mailto:robyn@fule.com.au">robyn@fule.com.au.</a>
      </p>
      <p className={paragraphStyles}>
        We will treat your requests or complaints confidentially.
      </p>
      <p className={paragraphStyles}>
        Our representative will contact you within a reasonable time after
        receipt of your complaint to discuss your concerns and outline options
        regarding how they may be resolved.
      </p>
      <p className={paragraphStyles}>
        We will aim to ensure that your complaint is resolved in a timely and
        appropriate manner.
      </p>
      <h3 className={headingStyles}>Changes to our privacy policy</h3>
      <p className={paragraphStyles}>
        We may change this privacy policy from time to time, however we will
        notify you of any changes to our privacy policy as and when they are
        made.
      </p>
      <p className={paragraphStyles}>
        Any updated versions of this privacy policy will be posted on our
        website and will be effective from the date of posting. This privacy
        policy was last updated 24 May 2022.
      </p>
    </div>
  </>
);

export default Privacy;
