import { Elements } from '@stripe/react-stripe-js';
import {
  loadStripe,
  StripeElementsOptions,
  StripePaymentElementOptions,
} from '@stripe/stripe-js';
import { Field, Form, Formik } from 'formik';
import React, { useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { aggregators } from '../../../aggregators';
import { useAppContext } from '../../../common/context/AppContext';
import { Coupon } from '../../../common/models/Coupon';
import { CouponDuration } from '../../../common/models/CouponDuration';
import { PersonalInfo } from '../../../common/models/PersonalInfo';
import { environment } from '../../../environment';
import { PaymentPlan } from '../../../pages/Payment';
import { checkCoupon } from '../../../utils/api/checkCoupon';
import { createSubscription } from '../../../utils/api/createSubscription';
import { validatePersonalInfoFields } from '../../../utils/helpers/helpers';
import Button from '../../common/Button/Button';
import { ButtonTypes } from '../../common/Button/Button.types';
import Checkbox from '../../common/Checkbox/Checkbox';
import DropdownSelect from '../../common/DropdownSelect/DropdownSelect';
import Input from '../../common/Input/Input';
import SubscriptionModal from '../../modals/SubscriptionModal/SubscriptionModal';
import { TermsAndConditionsModal } from '../../modals/TermsAndConditionsModal/TermsAndConditionsModal';
import { PaymentForm } from '../PaymentForm/PaymentForm';
import { TermsAndConditionsLink } from '../TermsAndConditionsLink/TermsAndConditionsLink';

const stripePromise = loadStripe(environment.stripe_pk);

const paymentElementOptions = {
  layout: 'tabs',
} as StripePaymentElementOptions;

const getOptions = (type: PaymentPlan): StripeElementsOptions =>
  ({
    mode: 'subscription',
    currency: 'aud',
    appearance: {
      theme: 'stripe',
    },
    amount: type === PaymentPlan.YEAR_PLAN ? 810 : 75,
    payment_method_types: ['card'],
    paymentMethodCreation: 'manual',
    payment_method_creation: 'manual',
  } as StripeElementsOptions);

const initialValues: PersonalInfo = {
  firstName: '',
  lastName: '',
  email: '',
  confirmEmail: '',
  aggregator: '',
  agreement: false,
};

const formFieldContainerStyles = 'w-full flex max-md:flex-col';
const formFieldLabelStyles = 'w-1/3 font-medium text-sm max-sm:w-full';

const PersonalInfoForm = (): JSX.Element => {
  const [showModal, setShowModal] = useState(false);
  const [coupon, setCoupon] = useState('');
  const [couponStatus, setCouponStatus] = useState<Coupon | undefined>(
    undefined,
  );
  const [showTermsConditions, setShowTermsConditions] = useState(false);
  const { isLoading, setIsLoading, selectedPayment } = useAppContext();
  const navigate = useNavigate();
  const aggregatorsList = useMemo(
    () =>
      aggregators
        .map(name => ({ data: name }))
        .sort((a, b) => a.data.localeCompare(b.data)),
    [],
  );

  const formikRef = useRef<any>(null);

  const onApplyCoupon = async (): Promise<void> => {
    if (coupon) {
      setIsLoading(true);
      setCouponStatus(undefined);
      const result = await checkCoupon(selectedPayment, coupon.trim());
      setCouponStatus(result);
      setIsLoading(false);
    }
  };

  const getPriceOffPrompt = (
    percentOff: number | null,
    amountOff: number | null,
  ): string => {
    return percentOff ? `${percentOff}%` : amountOff ? `$${amountOff}` : '';
  };

  const getDurationPrompt = (
    duration: CouponDuration | null,
    durationInMonths: number | null,
  ): string => {
    switch (duration) {
      case 'once': {
        return 'first payment';
      }
      case 'forever': {
        return 'all next payments';
      }
      case 'repeating': {
        return `each monthly payment for next ${
          durationInMonths ?? 'unknown'
        } months`;
      }
      default: {
        return '';
      }
    }
  };

  return (
    <div className="bg-white w-full max-w-[883px] p-8 border border-gray-extralight rounded-lg gap-2 text-primaryText-2 flex flex-col">
      <div className="mb-6 max-lg:mb-0">
        <h3 className="text-2xl font-bold mb-2.5">Personal information</h3>
        <p className="text-sm mb-5">Enter your personal details here.</p>
      </div>
      <Formik
        innerRef={formikRef}
        initialValues={initialValues}
        validate={values =>
          validatePersonalInfoFields(
            values,
            selectedPayment === PaymentPlan.BASE_PLAN,
          )
        }
        onSubmit={async values => {
          if (selectedPayment === PaymentPlan.BASE_PLAN) {
            setIsLoading(true);
            await createSubscription(values, PaymentPlan.BASE_PLAN);
            setShowModal(true);
            setIsLoading(false);
          }
        }}
      >
        {({ setFieldValue, errors, isValid }) => (
          <Form>
            <div className="flex flex-col mx-auto">
              <div className={formFieldContainerStyles}>
                <p className={formFieldLabelStyles}>Name</p>
                <div className="flex gap-4 w-2/3 max-xl:flex-col max-md:w-full">
                  <Field
                    name="firstName"
                    id="firstName"
                    component={Input}
                    placeholder="First name"
                    type="text"
                    styles="flex-1"
                  />
                  <Field
                    name="lastName"
                    id="lastName"
                    component={Input}
                    placeholder="Last name"
                    type="text"
                  />
                </div>
              </div>
              <hr className="my-5 border-gray-200 max-md:my-7" />
              <div className={formFieldContainerStyles}>
                <p className={formFieldLabelStyles}>Email address</p>
                <Field
                  name="email"
                  id="email"
                  component={Input}
                  styles="w-2/3 mb-5 max-md:w-full"
                  placeholder="mail@fule.com.au"
                  type="email"
                />
              </div>
              <div className={formFieldContainerStyles}>
                <p className={formFieldLabelStyles}>Confirm email address</p>
                <Field
                  name="confirmEmail"
                  id="confirmEmail"
                  component={Input}
                  styles="w-2/3 max-md:w-full"
                  placeholder="mail@fule.com.au"
                  type="email"
                />
              </div>
              <hr className="my-5 border-gray-200 max-md:my-7" />
              <div className={formFieldContainerStyles}>
                <p className={formFieldLabelStyles}>Aggregator</p>
                <div className="w-2/3 max-md:w-full">
                  <DropdownSelect
                    onSelection={selected =>
                      setFieldValue('aggregator', selected)
                    }
                    options={aggregatorsList}
                    selected="Select Aggregator"
                  />
                  {errors.aggregator && (
                    <p className="text-error text-sm mt-1">
                      {errors.aggregator}
                    </p>
                  )}
                </div>
              </div>
            </div>
            {selectedPayment !== PaymentPlan.BASE_PLAN && (
              <>
                <hr className="my-5 border-gray-200 max-md:my-7" />
                <div className={`${formFieldContainerStyles} my-2`}>
                  <p className={formFieldLabelStyles}>Promo Code</p>
                  <Input
                    name="coupon"
                    type="text"
                    id="coupon"
                    placeholder="Promo code"
                    onChange={e => {
                      setCouponStatus(undefined);
                      setCoupon(e.target.value.trim());
                    }}
                    styles="mr-4 grow"
                  />
                  <Button
                    buttonType={ButtonTypes.ACTION}
                    size="sm"
                    type="button"
                    text="Apply code"
                    styles="max-lg:my-3 max-lg:py-2 max-lg:px-5 text-sm ml-auto"
                    onClick={() => onApplyCoupon()}
                  />
                </div>
                <div className="flex justify-end">
                  {couponStatus?.totalPrice && (
                    <p className="text-success text-sm mt-1 w-2/3">
                      Promo code successfully applied. You will receive a
                      <b>{` ${getPriceOffPrompt(
                        couponStatus.percentOff,
                        couponStatus.amountOff,
                      )} discount `}</b>{' '}
                      on{' '}
                      {`${getDurationPrompt(
                        couponStatus.duration,
                        couponStatus.durationInMonths,
                      )}`}
                      . Total subscription price is{' '}
                      <b>${couponStatus.totalPrice.toFixed(2)}</b>
                    </p>
                  )}
                  {(couponStatus?.totalPrice === null ||
                    couponStatus?.valid === false) && (
                    <p className="text-error text-sm mt-1 w-2/3">
                      Sorry this coupon does not exist or has been expired
                    </p>
                  )}
                </div>
              </>
            )}
            {selectedPayment === PaymentPlan.BASE_PLAN && (
              <div className="mt-4">
                <Field name="agreement" id="agreement" component={Checkbox}>
                  <TermsAndConditionsLink
                    onClick={() => {
                      setShowTermsConditions(true);
                    }}
                  />
                </Field>
              </div>
            )}
            {selectedPayment === PaymentPlan.BASE_PLAN && (
              <div className="flex justify-end mt-2">
                <Button
                  type="submit"
                  buttonType={ButtonTypes.ACTION}
                  text="Subscribe"
                  disabled={isLoading || !isValid}
                  styles="mt-8 max-lg:py-2 max-lg:px-5 max-lg:text-sm"
                />
              </div>
            )}
          </Form>
        )}
      </Formik>
      {selectedPayment !== PaymentPlan.BASE_PLAN && (
        <div className="flex flex-col">
          <div className="mb-6 max-lg:mb-0">
            <h3 className="text-2xl font-bold mb-2.5">Payment details</h3>
            <p className="text-sm mb-5">Enter your billing details.</p>
          </div>

          <div className={formFieldContainerStyles}>
            <p className={formFieldLabelStyles}>Card details</p>
            <div className="w-2/3 gap-4">
              <Elements
                options={getOptions(selectedPayment)}
                stripe={stripePromise}
              >
                <PaymentForm
                  onSubmit={async () => {
                    await formikRef.current.submitForm();
                    const isValid = formikRef.current.isValid;
                    if (isValid) {
                      return formikRef.current.values;
                    }
                  }}
                  stripePaymentElementOptions={paymentElementOptions}
                  coupon={coupon}
                />
              </Elements>
            </div>
          </div>
        </div>
      )}

      {showTermsConditions && (
        <TermsAndConditionsModal
          onClose={() => setShowTermsConditions(false)}
        />
      )}
      {showModal && (
        <SubscriptionModal
          onClose={e => {
            e?.stopPropagation();
            setShowModal(false);
            navigate('/home');
          }}
        />
      )}
    </div>
  );
};

export default PersonalInfoForm;
