/* eslint-disable @typescript-eslint/no-empty-function,@typescript-eslint/no-unused-vars */
import React, { createContext, FC, useContext, useState } from 'react';

import { PaymentPlan } from '../../pages/Payment';

interface AppContext {
  isModalOpen: boolean;
  setIsModalOpen: (c: boolean) => void;
  isLoading: boolean;
  setIsLoading: (c: boolean) => void;
  selectedPayment: PaymentPlan;
  setSelectedPayment: (p: PaymentPlan) => void;
}

const defaultContext: AppContext = {
  isModalOpen: false,
  setIsModalOpen: (c: boolean) => {},
  isLoading: false,
  setIsLoading: (c: boolean) => {},
  selectedPayment: PaymentPlan.BASE_PLAN,
  setSelectedPayment: (p: PaymentPlan) => {},
};

const AppContext = createContext(defaultContext);

const useAppContext = (): AppContext => useContext(AppContext);

const AppContextProvider: FC<{ children?: React.ReactNode }> = ({
  children,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedPayment, setSelectedPayment] = useState<PaymentPlan>(
    PaymentPlan.BASE_PLAN,
  );

  return (
    <AppContext.Provider
      value={{
        isLoading,
        setIsLoading,
        isModalOpen,
        setIsModalOpen,
        selectedPayment,
        setSelectedPayment,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export { useAppContext, AppContextProvider };
