import { Field, Form, Formik } from 'formik';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { createKeapContact } from '../../../utils/api/createKeapContact';
import { validateWaitlistFields } from '../../../utils/helpers/helpers';
import Button from '../../common/Button/Button';
import { ButtonTypes } from '../../common/Button/Button.types';
import Checkbox from '../../common/Checkbox/Checkbox';
import Input from '../../common/Input/Input';
import Modal from '../../common/Modal/Modal';
import { FormFields, WaitlistModalProps } from './WaitlistModal.types';

export const initialValues: FormFields = {
  email: '',
  firstName: '',
  lastName: '',
  phone: '',
  policy: false,
};

const WaitlistModal = ({ onClose }: WaitlistModalProps): JSX.Element => {
  const [isSuccess, setIsSuccess] = useState<boolean | undefined>();
  return (
    <Modal onClose={onClose} styles="max-w-[540px]">
      <Formik
        initialValues={initialValues}
        validate={validateWaitlistFields}
        onSubmit={async (values, actions) => {
          const { email, firstName, lastName, phone, policy } = values;
          actions.setSubmitting(false);
          const result = await createKeapContact({
            email,
            confirmEmail: email,
            firstName,
            lastName,
            phone,
            agreement: policy,
          });
          setIsSuccess(result.ok);
        }}
      >
        {({ isSubmitting }) => (
          <Form>
            <div className="flex flex-col gap-4 max-w-[480px] mx-auto">
              <Field
                name="firstName"
                id="firstName"
                component={Input}
                label="First Name"
                type="text"
              />
              <Field
                name="lastName"
                id="lastName"
                component={Input}
                label="Last Name"
                type="text"
              />
              <Field
                name="email"
                id="email"
                component={Input}
                label="Email"
                type="email"
              />
              <Field
                name="phone"
                id="phone"
                component={Input}
                label="Phone"
                type="tel"
              />
              <Field name="policy" id="policy" component={Checkbox}>
                <label
                  htmlFor="policy"
                  className="ml-2 text-md font-medium text-gray-900 dark:text-gray-300"
                >
                  I agree to Fule’s{' '}
                  <Link
                    to="/privacy-policy"
                    target="_blank"
                    className="underline"
                  >
                    Terms & Conditions and Privacy Policy
                  </Link>
                  .
                </label>
              </Field>
              <Button
                type="submit"
                buttonType={ButtonTypes.PRIMARY}
                text="Submit"
                disabled={isSubmitting}
                styles="self-start"
              />
              {isSuccess !== undefined && (
                <div
                  className={`border-2 ${
                    isSuccess ? 'border-success' : 'border-failure'
                  } px-4 py-1 my-4 mx-2 text-center`}
                >
                  {isSuccess
                    ? 'Thank you for your enquiry! We will contact you soon!'
                    : 'There was an error. Please try again'}
                </div>
              )}
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default WaitlistModal;
