import React from 'react';
import { useNavigate } from 'react-router-dom';

import grayFlow from '../../../assets/images/image.svg';
import image from '../../../assets/images/image-block.png';
import Button from '../../common/Button/Button';

const ImageBlock = (): JSX.Element => {
  const navigate = useNavigate();
  return (
    <>
      <div className="relative">
        <div className="bg-secondary-base container max-w-[1552px]">
          <div className="flex container max-w-[1200px] pt-10 max-lg:py-20 max-md:py-14 max-md:px-2.5 max-md:flex-col items-center">
            <div className="w-2/3 max-lg:w-full text-secondaryText-1 self-center mb-8">
              <h2 className="p-0 mb-4 text-3xl">
                Are you ready to take your business <br /> to the{' '}
                <span className="font-bold">next level</span>?
              </h2>
              <h3 className="p-0 mb-7 text-xl">
                Take us for a test drive to see how Fule can support <br /> your
                business!
              </h3>
              <Button
                text="Free trial"
                styles="max-md:w-full"
                onClick={() => navigate('/payment')}
              />
            </div>
            <div className="w-1/3 max-lg:hidden lg:block max-md:block max-md:w-full max-md:max-w-[220px]">
              <img src={image} alt="Fule Join Waitlist" />
            </div>
          </div>
        </div>
        <img
          src={grayFlow}
          alt="Gray Flow"
          className="w-screen absolute transform -scale-y-100 bottom-0 left-0 z-[-100] h-[258px]"
        />
      </div>
    </>
  );
};
export default ImageBlock;
