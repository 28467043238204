import React from 'react';
import { useNavigate } from 'react-router-dom';

import heroImage from '../assets/images/hero.png';
import { useAppContext } from '../common/context/AppContext';
import Button from '../components/common/Button/Button';
import { ButtonTypes } from '../components/common/Button/Button.types';
import PricingCard from '../components/PricingCard/PricingCard';
import { PaymentPlan } from './Payment';

const pricingBtnStyles = 'py-2 px-3.5';

const commonAdvantages = [
  'Unlimited access to all fule insights',
  'Localised market insights',
  'Coaching and tips to help you succeed',
  '300,000+ businesses',
  'Social media content',
];
const paymentRoute = '/payment';

const Pricing = (): JSX.Element => {
  const { setSelectedPayment, setIsModalOpen } = useAppContext();
  const navigate = useNavigate();

  return (
    <div className="container max-w-[840px]">
      <div className="flex gap-14 items-center max-md:flex-col max-md:gap-10 my-12">
        <img src={heroImage} alt="Fule Image" className="mx-auto h-[200px]" />
        <div className="flex flex-col max-md:items-center text-primaryText-2">
          <h1 className="font-bold text-4.5xl max-md:text-3xl max-md:leading-[54px]">
            Pricing
          </h1>
          <p className="max-md:text-center">
            We want to level the ‘playing field’ and make data affordable to
            brokers of all shapes and sizes! Whether you are brand new to the
            industry or part of the broking industry furniture, we have a plan
            to suit your needs!
          </p>
        </div>
      </div>
      <div className="flex gap-5 mb-32 max-md:flex-col max-md:items-center">
        <PricingCard
          price="$75"
          advantages={['Single user', ...commonAdvantages]}
          pricingInfo="Or $810 per year - save 10%"
          title="Standard"
        >
          <>
            <Button
              text="Subscribe"
              styles={pricingBtnStyles}
              onClick={() => {
                setSelectedPayment(PaymentPlan.YEAR_PLAN);
                navigate(paymentRoute);
              }}
            />
            <Button
              buttonType={ButtonTypes.SECONDARY}
              text="Try for free"
              styles={pricingBtnStyles}
              onClick={() => {
                setSelectedPayment(PaymentPlan.BASE_PLAN);
                navigate(paymentRoute);
              }}
            />
          </>
        </PricingCard>
        <PricingCard
          advantages={[
            '5 or more users',
            ...commonAdvantages,
            'Customisable modules',
            'Ability to white label',
          ]}
          pricingInfo="The fule platform is fully customisable. Bespoke pricing to match your business needs."
          title="Enterprise"
        >
          <>
            <Button
              text="Contact us"
              styles={pricingBtnStyles}
              onClick={e => {
                e?.stopPropagation();
                setIsModalOpen(true);
              }}
            />
            <Button
              buttonType={ButtonTypes.SECONDARY}
              text="Try for free"
              styles={pricingBtnStyles}
              onClick={() => {
                setSelectedPayment(PaymentPlan.BASE_PLAN);
                navigate(paymentRoute);
              }}
            />
          </>
        </PricingCard>
      </div>
    </div>
  );
};

export default Pricing;
