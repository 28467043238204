import React, { FC } from 'react';

import { IconProps } from '../Icon.types';

export const ArrowDownIcon: FC<IconProps> = ({ fill = '#5C5C65', styles }) => (
  <svg
    width="12"
    height="8"
    viewBox="0 0 12 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={styles}
  >
    <path
      d="M10.59 0.295017L6 4.87502L1.41 0.295017L0 1.70502L6 7.70502L12 1.70502L10.59 0.295017Z"
      fill={fill}
    />
  </svg>
);
