import React from 'react';
import { Oval } from 'react-loader-spinner';

export const Loader: React.FC = () => (
  <div className="flex items-center justify-center bg-[#6C6C6C] w-full h-full fixed top-0 left-0 z-[999] opacity-50">
    <Oval
      height={120}
      width={120}
      color="#62BDB5"
      visible={true}
      ariaLabel="oval-loading"
      secondaryColor="#ffffff"
      strokeWidth={5}
      strokeWidthSecondary={5}
    />
  </div>
);
