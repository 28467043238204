import React, { useEffect } from 'react';

const useOutsideAlerter = (
  ref: React.RefObject<HTMLDivElement>,
  callback: () => void,
): void => {
  const handleClick = (event: Event): void => {
    const target = event.target as Node;
    if (ref.current && !ref.current.contains(target)) {
      callback();
    }
  };
  useEffect(() => {
    document.addEventListener('click', handleClick);
    return () => {
      document.removeEventListener('click', handleClick);
    };
  });
};

export default useOutsideAlerter;
