import {
  PaymentElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { StripePaymentElementOptions } from '@stripe/stripe-js';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAppContext } from '../../../common/context/AppContext';
import { PersonalInfo } from '../../../common/models/PersonalInfo';
import { PaymentPlan } from '../../../pages/Payment';
import { createSubscription } from '../../../utils/api/createSubscription';
import Button from '../../common/Button/Button';
import { ButtonTypes } from '../../common/Button/Button.types';
import Checkbox from '../../common/Checkbox/Checkbox';
import SubscriptionModal from '../../modals/SubscriptionModal/SubscriptionModal';
import { TermsAndConditionsModal } from '../../modals/TermsAndConditionsModal/TermsAndConditionsModal';
import { TermsAndConditionsLink } from '../TermsAndConditionsLink/TermsAndConditionsLink';

export const PaymentForm: React.FC<{
  stripePaymentElementOptions: StripePaymentElementOptions;
  onSubmit: () => Promise<PersonalInfo>;
  coupon?: string;
}> = ({ stripePaymentElementOptions, onSubmit, coupon }) => {
  const { selectedPayment } = useAppContext();
  const [showModal, setShowModal] = useState(false);
  const [showTermsConditions, setShowTermsConditions] = useState(false);
  const [isAgreementChecked, setIsAgreementChecked] = useState(false);
  const [isAgreementTouched, setIsAgreementTouched] = useState(false);

  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState<string | null>(null);
  const { setIsLoading } = useAppContext();

  const navigate = useNavigate();

  useEffect(() => {
    if (message) setIsLoading(false);
  }, [message]);

  const handleSubmit = async (e: any): Promise<void> => {
    setIsLoading(true);
    setMessage(null);
    e.preventDefault();
    setIsAgreementTouched(true);
    const values = await onSubmit();
    if (!stripe || !elements || !values) {
      setIsLoading(false);
      return;
    }
    // Trigger form validation and wallet collection
    const { error: submitError } = await elements.submit();
    if (submitError || !isAgreementChecked) {
      setIsLoading(false);
      return;
    }
    const { clientSecret } = await createSubscription(
      {
        ...values,
        ...(selectedPayment !== PaymentPlan.BASE_PLAN
          ? { agreement: isAgreementChecked }
          : {}),
      },
      selectedPayment,
      coupon,
    );

    if (!clientSecret) {
      setMessage(
        'Unable To Subscribe. You already have an active subscription.',
      );
      return;
    }

    const { error } = await stripe.confirmPayment({
      elements,
      clientSecret,
      confirmParams: {
        payment_method_data: {
          billing_details: {
            email: values.email,
            name: JSON.stringify({ type: selectedPayment }),
          },
        },
      },
      redirect: 'if_required',
    });

    if (clientSecret && !error) {
      setIsLoading(false);
      setShowModal(true);
    }

    if (error?.type === 'card_error' || error?.type === 'validation_error') {
      setMessage(error?.message ?? 'An unexpected error occurred.');
    }
  };

  return (
    <>
      <form id="payment-form" onSubmit={handleSubmit}>
        <PaymentElement
          id="payment-element"
          options={stripePaymentElementOptions}
        />

        {message && (
          <div id="payment-message" className="text-lg text-error mt-2">
            {message}
          </div>
        )}
        <div className="mt-4">
          <Checkbox
            id="agreement"
            isChecked={isAgreementChecked}
            setIsChecked={setIsAgreementChecked}
            setIsTouched={setIsAgreementTouched}
          >
            <TermsAndConditionsLink
              onClick={() => {
                setShowTermsConditions(true);
              }}
            />
          </Checkbox>
          {!isAgreementChecked && isAgreementTouched && (
            <span className="text-error text-sm mt-1">
              You must accept Terms and Conditions before accessing Fule.
            </span>
          )}
        </div>
        <div className="flex justify-end mt-2">
          <Button
            buttonType={ButtonTypes.ACTION}
            type="submit"
            text="Subscribe"
            styles="mt-8 max-lg:py-2 max-lg:px-5 max-lg:text-sm"
          />
        </div>
      </form>
      {showTermsConditions && (
        <TermsAndConditionsModal
          onClose={() => setShowTermsConditions(false)}
        />
      )}
      {showModal && (
        <SubscriptionModal
          onClose={e => {
            e?.stopPropagation();
            setShowModal(false);
            navigate('/home');
          }}
        />
      )}
    </>
  );
};
