import React, { FC } from 'react';

import { IconProps } from './Icon.types';

export const MainLogo: FC<IconProps> = ({ styles }) => (
  <svg
    width="356"
    height="132"
    viewBox="0 0 356 132"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={styles}
  >
    <path
      d="M147.96 31.1002V46.3301H167.59V61.7302H147.96V129.42H130.19V61.7302H118.34V46.3301H130.19V29.5801C130.19 9.44014 142.21 0.640137 159.81 0.640137C164.55 0.640137 168.1 1.15016 171.32 2.16016V17.2202C168.61 16.8802 166.41 16.7101 164.21 16.7101C154.05 16.7101 147.96 20.1002 147.96 31.1002Z"
      fill="#40BFB4"
    />
    <path
      d="M167.25 94.5601V46.3301H184.85V95.9101C184.85 107.59 193.14 114.86 203.63 114.86C213.78 114.86 221.91 107.58 221.91 95.9101V46.3301H239.68V94.5601C239.68 117.07 223.94 131.28 203.63 131.28C182.98 131.28 167.25 117.07 167.25 94.5601Z"
      fill="#40BFB4"
    />
    <path d="M249.02 2.5H266.79V129.42H249.02V2.5Z" fill="#40BFB4" />
    <path
      d="M353.78 93.71H291.84C294.21 107.42 304.7 115.54 318.92 115.54C328.57 115.54 336.18 111.82 343.12 105.72L351.58 117.9C342.78 126.19 331.61 131.27 318.41 131.27C292.69 131.27 273.73 112.49 273.73 87.95C273.73 63.58 292.51 44.46 316.71 44.46C338.88 44.46 355.12 60.7099 355.12 82.5399C355.13 87.1099 354.28 91.51 353.78 93.71ZM292.18 79.84H337.36C337.02 66.81 327.38 59.53 316.04 59.53C304.36 59.53 294.88 67.48 292.18 79.84Z"
      fill="#40BFB4"
    />
    <path
      d="M7.57001 130.49C6.52001 130.49 5.67 130.27 5.12 130.07C1.96 128.92 0 125.69 0 121.66V66.94C0 60.48 5.25001 55.23 11.71 55.23H65C71.46 55.23 76.71 60.48 76.71 66.94V102.64C76.71 109.1 71.46 114.35 65 114.35H25.48L15.21 126.29C12.36 129.67 9.58001 130.49 7.57001 130.49ZM11.71 65.65C11.01 65.65 10.42 66.24 10.42 66.94V115.89L20.71 103.93H65C65.7 103.93 66.29 103.34 66.29 102.64V66.94C66.29 66.24 65.7 65.65 65 65.65H11.71Z"
      fill="#FEB600"
    />
    <path
      d="M99.85 102.32C97.83 102.32 95.04 101.5 92.16 98.0801L81.92 86.1801H42.41C35.95 86.1801 30.7 80.9301 30.7 74.4701V38.7701C30.7 32.3101 35.95 27.0601 42.41 27.0601H95.7C102.16 27.0601 107.41 32.3101 107.41 38.7701V93.4901C107.41 97.5201 105.45 100.75 102.29 101.9C101.74 102.1 100.9 102.32 99.85 102.32ZM42.41 37.4801C41.71 37.4801 41.12 38.0701 41.12 38.7701V74.4701C41.12 75.1701 41.71 75.7601 42.41 75.7601H86.7L96.99 87.7201V38.7701C96.99 38.0701 96.4 37.4801 95.7 37.4801H42.41Z"
      fill="#40BFB4"
    />
  </svg>
);
