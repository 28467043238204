import React from 'react';

import { PaymentPlan } from '../../../pages/Payment';
import { CheckMarkedIcon } from '../../icons/Check/CheckMarked';
import { CheckUnmarkedIcon } from '../../icons/Check/CheckUnmarked';

interface PricingCardProps {
  isSelected: boolean;
  plan: string;
  type: PaymentPlan;
  price?: string;
  pricingInfo: string;
  description?: string;
  onClick: () => void;
}

const checkIconStyles = 'm-auto';

const PaymentPlanCard = ({
  isSelected,
  plan,
  description,
  price,
  pricingInfo,
  onClick,
}: PricingCardProps): JSX.Element => {
  return (
    <div
      className="bg-white md:max-w-[417px] py-5 px-2.5 border border-gray-extralight rounded-lg flex gap-2 cursor-pointer max-md:w-full"
      onClick={onClick}
    >
      <div className="w-6 h-6 flex items-center justify-center ml-9">
        {isSelected ? (
          <CheckMarkedIcon styles={checkIconStyles} />
        ) : (
          <CheckUnmarkedIcon styles={checkIconStyles} />
        )}
      </div>
      <div className="flex flex-col text-primaryText-2">
        <p className="font-semibold">{plan}</p>
        <p className="mb-2">
          <span className="font-semibold">{price} </span>
          {pricingInfo}
        </p>
        {description && <p className="text-gray-light">{description}</p>}
      </div>
    </div>
  );
};

export default PaymentPlanCard;
