import React from 'react';

import { useAppContext } from '../../../common/context/AppContext';
import { PaymentPlan } from '../../../pages/Payment';
import Button from '../../common/Button/Button';
import { ButtonTypes } from '../../common/Button/Button.types';
import Modal from '../../common/Modal/Modal';

const SubscriptionModal = ({
  onClose,
}: {
  onClose: (e?: React.MouseEvent<HTMLElement>) => void;
}): JSX.Element => {
  const { selectedPayment } = useAppContext();
  return (
    <Modal onClose={onClose} styles="max-w-[460px]">
      <div className="flex flex-col items-center my-6">
        <p className="text-2xl font-bold self-center mb-6"> Welcome to Fule!</p>
        <p className="my-2">
          Your{' '}
          {selectedPayment === PaymentPlan.BASE_PLAN
            ? 'free'
            : selectedPayment === PaymentPlan.STANDARD_PLAN
            ? 'monthly'
            : 'annual'}{' '}
          subscription has been set up.
        </p>
        <p className="mt-2">
          An email will be sent with your username and password.
        </p>
        <p className="mt-2 mb-6">
          Check your junk/other folder if not in your inbox shortly.
        </p>
        <Button
          type="submit"
          buttonType={ButtonTypes.ACTION}
          text="Ok"
          onClick={onClose}
        />
      </div>
    </Modal>
  );
};

export default SubscriptionModal;
