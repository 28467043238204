import React from 'react';

import Carousel from '../components/Home/Carousel/Carousel';
import Hero from '../components/Home/Hero/Hero';
import ImageBlock from '../components/Home/ImageBlock/ImageBlock';
import Stages from '../components/Home/Stages/Stages';
import Videos from '../components/Home/Videos/Videos';

const Home = (): JSX.Element => (
  <>
    <Hero />
    <Stages />
    <Videos />
    <Carousel />
    <ImageBlock />
  </>
);

export default Home;
