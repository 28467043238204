import React from 'react';

import { ButtonSize } from './Button.styles';

export enum ButtonTypes {
  PRIMARY,
  SECONDARY,
  ACTION,
}

export interface ButtonProps {
  buttonType?: ButtonTypes;
  size?: ButtonSize;
  text: string;
  disabled?: boolean;
  type?: 'submit' | 'reset' | 'button';
  onClick?: (e?: React.MouseEvent<HTMLElement>) => void;
  href?: string;
  styles?: string;
}
