import React from 'react';

import { TickIcon } from '../icons/TickIcon';

interface PricingCardProps {
  title: string;
  price?: string;
  pricingInfo: string;
  advantages: string[];
  children: JSX.Element;
}

const PricingCard = ({
  children,
  title,
  advantages,
  price,
  pricingInfo,
}: PricingCardProps): JSX.Element => (
  <div className="flex flex-col bg-white border border-gray-extralight rounded-lg w-full max-w-[410px] text-primaryText-2 py-10 px-9">
    <h3 className="text-3xl font-semibold mb-5 max-md:text-[28px]">{title}</h3>
    <div className="flex flex-col gap-2 h-[68px] justify-center">
      {price && (
        <p>
          <span className="text-4xl font-bold">{price}</span> per month
        </p>
      )}
      <p>{pricingInfo}</p>
    </div>
    <hr className="my-9 border-gray-extralight" />
    <div className="flex flex-col gap-5 mb-10">
      {advantages.map((a, i) => (
        <div key={`pricing-advantage-${i}`} className="flex items-center">
          <TickIcon styles="inline mr-4" /> <p>{a}</p>
        </div>
      ))}
    </div>
    <div className="flex flex-col gap-3 nb-0 mt-auto">{children}</div>
  </div>
);

export default PricingCard;
