import { FieldProps } from 'formik';
import React, { FC } from 'react';

import { InputProps } from './Input.types';

const inputStyles =
  'bg-white border border-gray-extralight py-2 pr-3 pl-4 focus:text-primaryText-3 rounded placeholder:text-gray-light';
const inputFocusStyles =
  'focus:border focus:border-gray-base focus:text-primaryText-1 focus:outline-0 focus:rounded focus:shadow-md';

const Input: FC<InputProps & Partial<FieldProps>> = ({
  label,
  placeholder,
  type,
  id,
  field,
  styles,
  form,
  onChange,
  ...props
}): JSX.Element => (
  <div className={`flex flex-col ${styles}`}>
    {label && (
      <label htmlFor={id} className="text-primaryText-3 mb-[5px]">
        {label}
      </label>
    )}
    <input
      type={type}
      className={`${inputStyles} ${inputFocusStyles}`}
      placeholder={placeholder}
      onChange={onChange}
      {...field}
      {...props}
    />
    {field && form?.touched[field.name] && form?.errors[field.name] && (
      <span className="text-error text-sm mt-1">
        {form?.errors[field.name] as string}
      </span>
    )}
  </div>
);

export default Input;
