import { PersonalInfo } from '../../common/models/PersonalInfo';
import { environment } from '../../environment';

export const createKeapContact = async ({
  email,
  firstName,
  lastName,
  phone,
}: PersonalInfo): Promise<Response> => {
  return await fetch(`${environment.stripe_url}/createKeapContact`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      accept: 'application/json',
    },
    body: JSON.stringify({
      email,
      firstName,
      lastName,
      ...(phone ? { phone } : {}),
    }),
  });
};
