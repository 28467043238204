import { Coupon } from '../../common/models/Coupon';
import { environment } from '../../environment';
import { PaymentPlan } from '../../pages/Payment';

export const checkCoupon = async (
  type: PaymentPlan,
  coupon?: string,
): Promise<Coupon> => {
  const response = await fetch(`${environment.stripe_url}/checkCoupon`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      accept: 'application/json',
    },
    body: JSON.stringify({
      type,
      coupon,
    }),
  });
  return await response.json();
};
