import React from 'react';
import { HashLink as Link } from 'react-router-hash-link';

import { getBtnStyles } from './Button.styles';
import { ButtonProps, ButtonTypes } from './Button.types';

const Button = ({
  buttonType = ButtonTypes.PRIMARY,
  type,
  text,
  href,
  onClick,
  size,
  ...props
}: ButtonProps): JSX.Element => {
  const styles = getBtnStyles({ buttonType: buttonType, size, ...props });
  return href ? (
    <Link to={href} className={`${styles} inline-block`}>
      {text}
    </Link>
  ) : (
    <button className={styles} onClick={onClick} type={type}>
      {text}
    </button>
  );
};
export default Button;
