import { PersonalInfo } from '../../common/models/PersonalInfo';
import { environment } from '../../environment';
import { PaymentPlan } from '../../pages/Payment';

export const createSubscription = async (
  { email, firstName, lastName, aggregator, agreement }: PersonalInfo,
  type: PaymentPlan,
  coupon?: string,
): Promise<{ clientSecret: string }> => {
  const response = await fetch(`${environment.stripe_url}/createSubscription`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      accept: 'application/json',
    },
    body: JSON.stringify({
      email,
      firstName,
      lastName,
      type,
      agreement,
      ...(aggregator ? { aggregator } : {}),
      ...(coupon ? { coupon } : {}),
    }),
  });
  return await response.json();
};
