import React from 'react';

import heroImage from '../assets/images/hero.png';
import teamImage6 from '../assets/images/team/Aaron_Milburn.png';
import teamImage7 from '../assets/images/team/Clive_Kirkpatrick.png';
import teamImage2 from '../assets/images/team/David_Ralph.png';
import teamImage3 from '../assets/images/team/Dong_Minh.jpg';
import teamImage5 from '../assets/images/team/Josh_Lindsay.png';
import teamImage4 from '../assets/images/team/Ly_Nguyen.png';
import teamImage1 from '../assets/images/team/Robyn_Koski.jpg';

const teamMembers = [
  {
    name: 'Robyn Koski',
    jobTitle: 'Founder',
    img: teamImage1,
  },
  {
    name: 'David Ralph',
    jobTitle: 'Founder',
    img: teamImage2,
  },
  {
    name: 'Dong Minh',
    jobTitle: 'Tech Lead',
    img: teamImage3,
  },
  {
    name: 'Ly Nguyen',
    jobTitle: 'Data Engineer',
    img: teamImage4,
  },
  {
    name: 'Josh Lindsay',
    jobTitle: 'Distribution',
    img: teamImage5,
  },
  {
    name: 'Aaron Milburn',
    jobTitle: 'Advisor',
    img: teamImage6,
  },
  {
    name: 'Clive Kirkpatrick',
    jobTitle: 'Advisor',
    img: teamImage7,
  },
];

const Team = (): JSX.Element => (
  <div className="container max-w-[840px]">
    <div className="flex gap-16 items-center mt-12 mb-20 max-md:flex-col max-md:mb-12">
      <img src={heroImage} alt="Welcome Image" className="h-[200px]" />
      <h1 className="text-4.5xl max-md:text-3xl font-bold text-primaryText-2 leading-[63px]">
        Meet the Fule team
      </h1>
    </div>
    <div className="flex flex-wrap items-center justify-center gap-y-14 gap-x-12 mb-32 max-md:mb-14">
      {teamMembers.map(({ name, jobTitle, img }) => (
        <div
          key={`team-${name}`}
          className="flex flex-col items-center w-[160px]"
        >
          <img
            src={img}
            alt={name}
            className="w-[110px] h-[110px] rounded-full mb-4 object-cover"
          />
          <h3 className="mb-2 font-bold text-lg text-primaryText-2">{name}</h3>
          <p className="text-primaryText-4">{jobTitle}</p>
        </div>
      ))}
    </div>
  </div>
);

export default Team;
