import image3 from '../../../assets/images/josh-lindsay.jpg';
import image2 from '../../../assets/images/mark-bouris.jpeg';
import image1 from '../../../assets/images/nancy-youssef.jpeg';
import image4 from '../../../assets/images/oscar-gee.png';

export const carouselData = [
  {
    texts: [
      'Mortgage brokers have an opportunity to own the space of being educators on property as an asset class.',
      'And if you’re a broker, you need to be communicating to your client base about this asset class. And you need, therefore, to understand about this asset class and the drivers of this asset class.',
      'So, if you’re addressing your client, you need to be able to have a conversation with your client, and you need to be able to talk, not necessarily as an expert, but with knowledge and confidence about that particular asset class.',
    ],
    imgSrc: image2,
    title: 'Mark Bouris',
    subtitle:
      'Australian Businessman, Founder Wizard Home Loans & YBR Chairman',
  },
  {
    texts: [
      'I’m lucky enough to have had early access to Fule and believe its potential to help educate brokers on property, diversify into SME lending and grow their networks is huge. If only this had been around back when I was a new broker. Life-changing!',
    ],
    imgSrc: image3,
    title: 'Joshua Lindsay',
    subtitle: 'Mortgage Broker at Evolve Money',
  },
  {
    texts: [
      'I got a sneak peak at Fule in the first few months of starting my broking career. Fule is like a hub for everything you need as a broker. It takes the clutter out of your day and gives you direction when feeling overwhelmed and not sure where to start.',
    ],
    imgSrc: image4,
    title: 'Oscar Gee',
    subtitle: 'Mortgage Broker at Award Home Loans',
  },
  {
    texts: [
      'Typically, brokers don’t dabble in commercial lending due to fear, the unknown, not knowing the answers and looking incompetent to their clients. They question their level of competence and their capability around commercial lending.',
      'This is not just about diversifying and making more income, although that is a valuable benefit of diversifying your offering as a broker. Rather, this is about the broker being there for the full life cycle of their customer, especially those business customers. It’s about relationships and solutions.',
    ],
    imgSrc: image1,
    title: 'Nancy Youssef',
    subtitle:
      'Multiple Award-Winning Finance Strategist, Business Mentor, Author, Speaker & Philanthropist',
  },
];
