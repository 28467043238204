import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import React from 'react';
import Slider from 'react-slick';

import { ArrowLeftIcon } from '../../icons/arrows/ArrowLeftIcon';
import { ArrowRightIcon } from '../../icons/arrows/ArrowRightIcon';
import { carouselData } from './CarouselData';
import SlickButton from './SlickButton';

const settings = {
  arrows: true,
  infinite: true,
  centerMode: true,
  speed: 500,
  slidesToShow: 2,
  centerPadding: '0px',
  slidesToScroll: 1,
  nextArrow: (
    <SlickButton>
      <ArrowRightIcon styles="w-[30px] h-[30px]" />
    </SlickButton>
  ),
  prevArrow: (
    <SlickButton>
      <ArrowLeftIcon styles="w-[30px] h-[30px]" />
    </SlickButton>
  ),
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};

const CarouselSlider = (): JSX.Element => (
  <div className="max-w-[1552px] container pt-14 pb-24">
    <h2 className="mb-4 text-3xl text-center">
      What is the
      <span className="font-bold"> industry </span>
      saying?
    </h2>
    <Slider {...settings}>
      {carouselData.map((c, i) => (
        <div
          key={`carousel-card-${i}`}
          className="p-12 pt-16 pl-14 max-md:px-4 m-2 shadow-card min-h-[470px] max-lg:min-h-[400px] relative before:bg-quotes before:absolute before:h-[42px] before:w-[49px] before:top-5 before:left-2.5"
        >
          <div className="flex flex-col justify-center min-h-[270px] max-md:min-h-[500px]">
            {c.texts.map((t, i) => (
              <p className="mb-4" key={`card-text-${i}`}>
                {t}
              </p>
            ))}
          </div>
          <div className="flex mt-8 max-md:flex-col">
            <img
              src={c.imgSrc}
              alt={c.title}
              className="rounded-full max-w-[100px] h-[100px] mr-4"
            />
            <div>
              <p className="font-bold text-xl">{c.title}</p>
              <p className="text-primaryText-2 text-sm">{c.subtitle}</p>
            </div>
          </div>
        </div>
      ))}
    </Slider>
  </div>
);

export default CarouselSlider;
