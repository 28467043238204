import React, { FC } from 'react';

import { IconProps } from './Icon.types';

export const TickIcon: FC<IconProps> = ({ fill = '#5C5C65', styles }) => (
  <svg
    width="16"
    height="11"
    viewBox="0 0 16 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={styles}
  >
    <path
      d="M14.5 0.0899963L6.50003 8.17L1.91003 3.59L0.500034 5L6.50003 11L15.7227 1.29688L14.5 0.0899963Z"
      fill={fill}
    />
  </svg>
);
