import React, { ReactNode, useEffect, useRef, useState } from 'react';

import useOutsideAlerter from '../../../utils/hooks/useOutsideAlerter';
import { ArrowDownIcon } from '../../icons/arrows/ArrowDownIcon';

interface SelectorOptionItem<T> {
  data: T;
  icon?: ReactNode;
}

interface SelectorProps<T> {
  label?: string;
  options: SelectorOptionItem<T>[];
  selected?: T;
  onSelection?: (selected: T) => void;
  targetComponent?: ReactNode;
}

const DropdownSelect = <T,>({
  onSelection,
  selected,
  options,
  label,
  targetComponent,
}: SelectorProps<T>): JSX.Element => {
  const [isDropDownOpened, setIsDropdownOpened] = useState(false);
  const [selectedOption, setSelectedOption] = useState<T | null>(
    !targetComponent ? selected ?? null : null,
  );

  useEffect(() => {
    setSelectedOption(selected ?? null);
  }, [selected]);

  const selectorRef = useRef<HTMLDivElement>(null);
  useOutsideAlerter(selectorRef, () => setIsDropdownOpened(false));

  return (
    <>
      {label && <p>{label}</p>}
      <div className="w-full relative" ref={selectorRef}>
        {targetComponent ? (
          <div
            onClick={e => {
              e.stopPropagation();
              setIsDropdownOpened(!isDropDownOpened);
            }}
          >
            {targetComponent}
          </div>
        ) : (
          <div
            className={`block bg-white text-primaryText-2 border border-gray-extralight rounded py-2 pr-3 pl-4 ${
              isDropDownOpened && 'border-gray-base rounded shadow-md'
            }`}
            onClick={() => setIsDropdownOpened(!isDropDownOpened)}
          >
            {selectedOption as ReactNode}
          </div>
        )}
        <ArrowDownIcon styles="absolute right-4 top-[40%]" />
        {isDropDownOpened && (
          <div
            className="absolute border border-gray-extralight z-[400] max-h-[350px] overflow-y-scroll"
            style={{ width: selectorRef?.current?.offsetWidth ?? 'auto' }}
          >
            {options.map((o, i) => (
              <div
                key={`option-${i}`}
                className="block py-2 pr-3 pl-4 bg-white text-primaryText-2 first:rounded-t last:rounded-b hover:bg-gray-extralight"
                onClick={e => {
                  e.stopPropagation();
                  setIsDropdownOpened(false);
                  setSelectedOption(o.data);
                  onSelection?.(o.data);
                }}
              >
                {o.data as ReactNode}
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  );
};
export default DropdownSelect;
