import React from 'react';

import arrowDown from '../../../assets/images/arrow-down-1.png';
import { ListIcon } from '../../icons/ListIcon';

const listItemStyles = 'mb-8 flex gap-2.5';

const stagesListTexts = [
  'Starting out',
  'Growing your business',
  'Diversifying your business',
  'Expanding your team',
  'Mentoring & coaching',
];

const Stages = (): JSX.Element => (
  <div className="bg-secondary-base relative top-negative" id="stages">
    <div className="max-w-[1200px] flex mx-auto pt-10 pb-14 px-4 max-md:flex-col-reverse max-md:gap-8">
      <div className="w-6/12 text-secondaryText-2 text-2xl relative md:pl-12 max-lg:text-[22px] max-md:w-full">
        <ul className="list-none z-10 relative max-lg:pl-2.5">
          {stagesListTexts.map((t, i) => (
            <li key={`stages-item-${i}`} className={listItemStyles}>
              <ListIcon styles="inline-block align-middle my-auto" />
              <span>{t}</span>
            </li>
          ))}
        </ul>
        <img
          src={arrowDown}
          alt="Arrow Down"
          className="absolute top-[10px] max-md:left-[11px] md:left-[60px] lg:left-12 lg:h-[541px] h-[450px]"
        />
      </div>
      <div className="w-6/12 text-secondaryText-1 self-center max-md:w-full">
        <h2 className="p-0 mb-7 text-3xl">
          Fule supports
          <span className="font-bold"> you at all stages </span>
          of your broking career. A platform tailored to your location and
          business goals
        </h2>
        <p className="p-0 text-xl mb-2">
          <span className="font-bold">Grow </span>
          your business and your confidence
        </p>
        <p className="p-0 text-xl mb-2">
          <span className="font-bold">Connect </span>
          with new clients and referrers
        </p>
        <p className="p-0 text-xl mb-2">
          <span className="font-bold">Learn </span>
          more about real estate, business and the economy
        </p>
      </div>
    </div>
  </div>
);
export default Stages;
